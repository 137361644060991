import './radioStyles.css'

const RadioButton = ({checked}) => {
    return (
      <label className="radio-label p-3">
        <input
          defaultChecked={checked}
          className="radio-input"
          type="radio"
        />
        <span className="custom-radio" />
      </label>
    )
  }

  export default RadioButton