import React, {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import { observer } from "mobx-react-lite";

type SideOverlayProps = {
  children: React.ReactNode,
  onClose: Function,
  show: boolean,
  title?: string,
  length:number,
}

export const SideOverlay = observer((props: SideOverlayProps) => {

  const {children, onClose, show, title="",length} = props;

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="fixed z-[99999] inset-0 overflow-hidden" onClose={()=>onClose()}>
        <div className="absolute inset-0 overflow-hidden font-medium">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-1000"
            enterFrom="opacity-0"
            enterTo="opacity-50"
            leave="ease-in-out duration-1000"
            leaveFrom="opacity-50"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-[#4E5664] bg-opacity-[0.1] transition-opacity"/>
          </Transition.Child>

          <div className="pointer-events-none fixed z-100 inset-y-0 right-0 flex max-w-md sm:max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-md z-100">
                <div className="flex h-full flex-col overflow-y-scroll py-4 shadow-xl bg-[#FEFEFE] rounded-l-lg">
                  <div className="px-4 sm:px-4">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-base pb-1 text-[#3C424C]">{title} {length && `(${length})`}</Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={() => onClose()}
                        >
                          <span className="sr-only">Close panel</span>
                          <i className="fa-solid fa-xmark text-[#737E93] fa-2x w-[12.5px] h-[12.5px] ring-none" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative flex-1">
                    {/*<div className="text-lg font-bold text-purple-600 uppercase">{title}</div>*/}
                    <div
                      className="pb-8 inline-block h-full align-bottom text-left overflow-hidden transform transition-all sm:my-2 sm:align-middle max-w-lg w-full">
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
)
})
