import { resolveObjectURL } from "buffer";
import apiSauce from "./api-sauce";

export class AdminApi {

  async get_templates_metadata(): Promise<any> {
    return await apiSauce.apisauce.get("templates/get_templates_metadata")
  }

  async create_update_templates(payload): Promise<any> {
    return await apiSauce.apisauce.post("templates/create_update_templates", payload)
  }
  async get_template():Promise<any>{
    return await apiSauce.apisauce.get("templates/get_template")
  }
  async delete_template(id): Promise<any> {
    return await apiSauce.apisauce.patch(`/templates/delete_template/${id}`)
  }
}
