import {Buffer} from "buffer";
import React from 'react';
import './css/index.css';
import {App} from './app';
import {StoreContext} from './hooks/use-store';
import {RootStore} from './store/root-store';
import {createRoot} from 'react-dom/client';
import './styles.css';
import { UserbackProvider } from "@userback/react";

window.Buffer = Buffer;

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <UserbackProvider token="40937|82543|hF1CLldRHnjmm4IHSt366yNlC">
  <StoreContext.Provider value={RootStore}>
    <App/>
  </StoreContext.Provider>
  </UserbackProvider>
);

