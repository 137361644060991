import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import TreeList from '../new-content/tree-list';
import { useStore } from '../../hooks/use-store';
import { safe_get } from '../utils';
import { useNavigate } from 'react-router';
import TableBody from '../dashboard/tableBody';

const SopFile = ({setIsOpen,isOpen}) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [createFolder, setCreateFolder] = useState(false);
  const { newContentStore,sopStore } = useStore();
  const navigate = useNavigate();
  const [activeDoc, setActiveDoc] = useState({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showRenameDocModal, setShowRenameDocModal] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const item_click = (item) => {
    const isfile = safe_get(item, 'isfile', false);
    if (!isfile) {
      // nothing
    } else {
      navigate(`/doc/${item['id']}`);
      //handle file click
    }
  };

  const deleteDoc = (event, doc) => {
    event.stopPropagation();
    setActiveDoc(doc);
    setShowDeleteConfirm(true);
  };

  const renameDoc = (event, doc) => {
    event.stopPropagation();
    setActiveDoc(doc);
    setShowRenameDocModal(true);
  };

  return (
    <>
      {/* <div className="fixed inset-0 flex items-center justify-center bg-gray-100 w-full"> */}
        {/* <button
          type="button"
          onClick={openModal}
          className="rounded-md bg-black px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
          Open dialog
        </button> */}
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-[1000]" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="mt-[50px] w-[60%] h-[500px] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 p-4"
                    >
                      Statement Of Purpose
                    </Dialog.Title>
                    <hr className="" />
                    <div className="sm:h-full md:h-full min-[500px] flex w-full overflow-auto flex-wrap">
                      <div
                        style={{ borderRight: '1px solid #E3E5E9' }}
                        className="relative bg-[#FEFEFE] xl:w-[25%] lg:w-[25%] md:w-full sm:w-full"
                      >
                        <div className="p-4">
                          <p
                            onClick={() => setCreateFolder(!createFolder)}
                            className="text-[#A033FF] text-[12px] font-bold pb-4 cursor-pointer"
                          >
                            Create New Folder
                          </p>
                          <TreeList />
                        </div>
                      </div>
                      <div className="rounded-lg bg-[#FEFEFE] xl:w-[75%] lg:w-[75%] md:w-full sm:w-full flex flex-col">
                        <div
                          style={{ borderBottom: '1px solid #E3E5E9' }}
                          className="flex items-center px-4 py-2"
                        >
                          <i className="fa-light fa-folder-open m-2 text-[#4E5664]" />
                          <p className="text-[#4E5664] text-[12px]">Folder Name</p>
                          <i className="fa-light fa-angle-right m-2" />
                          <i className="fa-light fa-folder m-2 text-[#4E5664]" />
                          <p className="text-[#4E5664] text-[12px]">Folder Name</p>
                          <i className="fa-light fa-angle-right m-2" />
                          <i className="fa-light fa-folder m-2 text-[#4E5664]" />
                          <p className="text-[#4E5664] text-[12px]">New Folder</p>
                        </div>
                        <div>
                          <table className="w-full text-sm text-left text-gray-500">
                            <thead className="text-[14px] text-[#737E93] border-b border-[#E3E5E9]">
                              <tr className="bg-[#F8F9FA]">
                                <th scope="col" className="px-6 py-3">
                                  File Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  Type
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  Last modified
                                </th>
                                <th scope="col" className="px-6 py-3" />
                              </tr>
                            </thead>
                            <tbody>
                              {newContentStore &&
                                newContentStore.children &&
                                newContentStore.children.length > 0 &&
                                newContentStore.children.map((item: any, index) => {
                                  return (
                                    <>
                                      <TableBody
                                        item_click={item_click}
                                        item={item}
                                        renameDoc={renameDoc}
                                        deleteDoc={deleteDoc}
                                        id={item.id}
                                      />
                                    </>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                        <div>
                          {createFolder ? (
                            <div className="p-6">
                              <p className="mb-2">New Folder Name</p>
                              <label className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
                                Search
                              </label>
                              <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"></div>
                                <input
                                  type="search"
                                  id="default-search"
                                  className="block w-full p-2 text-sm text-gray-900 border border-[#C7CBD4] rounded-lg bg-[#FEFEFE] dark:placeholder-gray-400"
                                  placeholder="Enter"
                                  required
                                />
                                <button
                                  type="submit"
                                  className="absolute right-2.5 bottom-0 rounded-lg text-[#A033FF] text-sm px-4 py-2 font-bold"
                                >
                                  Add Folder
                                </button>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div
                          style={{ borderTop: '1px solid #E3E5E9' }}
                          className="fixed bottom-0 flex justify-end w-[75%] z-10 bg-[#FEFEFE]"
                        >
                          <button
                            onClick={closeModal}
                            className="m-3 border border-[#8F98A9] p-2 text-[#8F98A9] rounded-md"
                          >
                            Cancel
                          </button>
                          <button className="bg-[#737E93] p-2 text-[#FEFEFE] rounded-md m-3 border border-[#8F98A9]">
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      {/* </div> */}
    </>
  );
};

export default SopFile;
