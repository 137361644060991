import { observer } from 'mobx-react-lite';

const ProfileCustomTextField = observer((props: any) => {
  return (
    <div className="w-full">
      <label
        className={` ${
          props.color ? `text-${props.color}` : 'text-[#737E93] '
        } left-0 -top-3.5 mb-[4px] text-[16px] leading-[22px] peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm`}
      >
        {props.label}
      </label>
      <input
        name={props.name}
        type={props.type}
        value={props.value}
        className="rounded-lg mt-[4px] bg-[#FEFEFE] mb-[24px] px-[10px] peer h-10 w-full border border-[#C7CBD4] text-gray-900 focus:outline-none focus:border-indigo-600"
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
    </div>
  );
});

export default ProfileCustomTextField;
