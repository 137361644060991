import apiSauce from '../../../api/api-sauce';


export class AIAssistService {

  constructor() {
  }

  async generate(instruction, text, options = {}) {
    const payload = {
      'text': text,
      'display': instruction,
      'options': options
    };
    const response = await apiSauce.apisauce.post('gen/ask-ai', payload);
    return response.data;
  }


}
