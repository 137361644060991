import apiSauce from "./api-sauce";

export class DocumentApi {

  async get_document_details(payload): Promise<any> {
    return await apiSauce.apisauce.post("library/document/details", JSON.stringify(payload))
  }

  async update_document_details(payload): Promise<any> {
    return await apiSauce.apisauce.post("library/document/update", JSON.stringify(payload))
  }

  async delete_folder(payload): Promise<any> {
    return await apiSauce.apisauce.post("library/delete_folder", JSON.stringify(payload))
  }

  async delete_file(payload): Promise<any> {
    return await apiSauce.apisauce.post("library/delete_file", JSON.stringify(payload))
  }

  async rename_file(payload): Promise<any> {
    return await apiSauce.apisauce.post("library/rename_file", JSON.stringify(payload))
  }

  async rename_folder(payload): Promise<any> {
    return await apiSauce.apisauce.post("library/rename_folder", JSON.stringify(payload))
  }

}
