import {observer} from "mobx-react-lite";
import React from "react";
import _ from 'lodash';
import {useNavigate} from "react-router-dom";
import {useStore} from "../../hooks/use-store";
import {toast} from "react-toastify";
import {safe_get} from "../utils";

const GenToolsList = observer((props: any) => {
  const navigate = useNavigate();
  const {toolsStore} = useStore()
  const {activeToolDetails} = props
  const path = window.location.pathname

  const getToolDetails = (id) => {
    if (path === '/dashboard') {
      navigate(`/toolslist/compose/${id}`)
    }
    if (path === '/profile/managetools') {
      navigate(`/tool/edit/${id}`)
    }
    if (path.includes('/doc/')) {
      toolsStore.set_selected_tool_id(id)
      toolsStore.toggle_content_composer_expanded_status()
    }
  }

  const deleteToolHandler = async (id) => {
    const res = await toolsStore.deleteTool(id)
    if (res.status === 200) {
      toast('Delete successful')
    }
  }

  const cloneToolHandler = async (id) => {
    const res = await toolsStore.cloneTool(id)
    if (res.status === 200) {
      toast('Clone successful')
    }
  }

  return (
    <>
      {path === '/profile/managetools' ?
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Subtitle
                    </th>
                    <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                    <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Delete</span>
                    </th>
                    <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Clone</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                  {!_.isEmpty(activeToolDetails) && Object.keys(activeToolDetails).map((key) => (
                    //{{party_workers_filtered_by_search.map((obj) => (
                    <tr key={safe_get(activeToolDetails, `${key}.id`, '')}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{safe_get(activeToolDetails, `${key}.meta.title`, '')}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{safe_get(activeToolDetails, `${key}.meta.subtitle`, '')}</td>

                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <a href="#" className="text-indigo-600 hover:text-indigo-900"
                           onClick={() => getToolDetails(safe_get(activeToolDetails, `${key}.id`, ''))}>
                          Edit<span className="sr-only">, {safe_get(activeToolDetails, `${key}.meta.title`, '')}</span>
                        </a>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <a href="#" className="text-indigo-600 hover:text-indigo-900"
                           onClick={() => deleteToolHandler(safe_get(activeToolDetails, `${key}.id`, ''))}>
                          Delete<span className="sr-only">, {safe_get(activeToolDetails, `${key}.meta.title`, '')}</span>
                        </a>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <a href="#" className="text-indigo-600 hover:text-indigo-900"
                           onClick={() => cloneToolHandler(safe_get(activeToolDetails, `${key}.id`, ''))}>
                          Clone<span className="sr-only">, {safe_get(activeToolDetails, `${key}.meta.title`, '')}</span>
                        </a>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        :
        <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-2">
          {!_.isEmpty(activeToolDetails) && Object.keys(activeToolDetails).map((key) => (
            <div key={safe_get(activeToolDetails, `${key}.id`, '')} onClick={() => getToolDetails(safe_get(activeToolDetails, `${key}.id`, ''))}>
              <li key={safe_get(activeToolDetails, `${key}.meta.title`, '')} className="col-span-1 flex rounded-md shadow-sm cursor-pointer">
                <div className='flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md bg-orange-400'>
                  {safe_get(activeToolDetails, `${key}.meta.category`, '') !== '' ? activeToolDetails[key].meta.category.charAt(0) : ''}
                </div>
                <div className="flex flex-1 items-center justify-between rounded-r-md border-t border-r border-b border-gray-200 bg-white">
                  <div className="flex-1 p-1 text-sm">
                    <div className="text-md font-bold uppercase overflow-ellipsis text-gray-600">
                      {safe_get(activeToolDetails, `${key}.meta.title`, '')}
                    </div>
                    <p className="text-gray-500">{safe_get(activeToolDetails, `${key}.meta.subtitle`, '')}</p>
                  </div>
                </div>
              </li>
            </div>
          ))}
        </ul>
      }
    </>
  )
})
export default GenToolsList
