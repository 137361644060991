import { useState } from 'react';
import { toast } from 'react-toastify';

type Props = {
  label: string;
  content: string;
  footerLabel: string;
  coinsLeft: number;
  id: number;
  infoIconClicked?: boolean;
  handleClick?: (arg1: number, arg2: string) => void;
  footerLabelTwo?:string;
  handleClicked?: () => void;
  index?:number;
  copiedIndex?:number
};

const TemplateCard = ({
  label,
  content,
  footerLabel,
  coinsLeft,
  id,
  infoIconClicked,
  handleClick,
  footerLabelTwo,
  handleClicked,
  index,
  copiedIndex
}: Props) => {

  return (
    <div className="block w-full rounded-lg my-4">
      <div className="flex justify-between text-[#292D35] text-[14px] rounded-t-lg border bg-[#F8F9FA] border-[#E3E5E9] p-3">
        {label}
        <div className="text-[#FFBB28] text-[12px]">
          <i className="fa-solid fa-coin"></i>
          <span className="ml-2">{coinsLeft}</span>
        </div>
      </div>
      <div className="p-3 border border-x-[#E3E5E9] bg-[#FEFEFE]">
        <p className="text-[12px] text-[#4E5664] leading-4">{content}</p>
      </div>
      <button
        onClick={() => {
          if (infoIconClicked) {
            navigator.clipboard.writeText(content);
            toast.success('Copied');
          } else {
            handleClick?.(id, content);
          }
          handleClicked?.()
        }}
        className="w-full border font-[900] text-center rounded-b-lg text-[12px] text-[#A033FF] bg-[#FCF3FF] border-[#E3E5E9] px-6 py-3"
      >
       {index == copiedIndex ? footerLabelTwo : footerLabel}
      </button>
    </div>
  );
};

export default TemplateCard;
