import _ from "lodash";
import S3 from 'react-aws-s3';

export function getParameterByName(name) {
  var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height
  };
}

export const safe_get = (map_object, key, defaultValue) => {

  try {
    let current = map_object;
    const parts = key.split('.');
    for (const part of parts) {
      if (part.startsWith('$')) {

      } else {
        if (Object.keys(current).includes(part)) {
          current = current[part];
        } else {
          return defaultValue;
        }
      }
    }
    return current;
  } catch (e) {

  }

  return defaultValue;
}

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}


export const makeid_alpa_numeric = (length) => {
  var result = '';
  var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const replaceObjectKeysWithoutChangingOrder = (obj, old_key, new_key) => {
  return _.mapKeys(obj, (v, k) => {
    return k === old_key ? new_key : k
  });
}


export const upload_file_to_s3 = (path, file: File, filename, bucket = "cambri", unique = false) => {
  const getFileExtension = (filename) => {
    const extension = filename.split('.').pop();
    return extension;
  };


  const getFileName = (filename) => {
    const name = filename.split('.');
    return name[0];
  };


  const s3config = {
    bucketName: bucket,
    dirName: path,
    region: 'ap-south-1',
    accessKeyId: "AKIAUIK3MDVV4YO3DSUE",
    secretAccessKey: "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",
  }

  // @ts-ignore
  const ReactS3Client = new S3(s3config);
  const id = makeid_alpa_numeric(6)
  const extn = getFileExtension(file.name);
  const newFileName = unique ? `${filename}_${id}.${extn}` : `${filename}.${extn}`;
  return ReactS3Client.uploadFile(file, newFileName);
  /*
    .then(data => {
      console.log('Uploaded', data);
      //toast.success("File uploaded successfully..")
    })
    .catch(err => {
      console.log('Failed', err);
    });

  const s3path = s3config.dirName + '/' + newFileName;
  // toast.success("File uploaded successfully..")
  return s3path
} catch (e) {
  //toast.error("Something went wrong..")
  console.log("error", e);
}
*/
}
