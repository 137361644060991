import {action, makeAutoObservable} from "mobx";
import {UserApi} from "../api/user-api";
import {makePersistable, clearPersistedStore} from 'mobx-persist-store';

export class UserStore {
  id: number = -1
  isLoggedIn: boolean = false
  token: string = ""
  email: string = ""
  loading: boolean = false
  type: string = ""
  verified: boolean = false
  isenterprise: boolean = false
  info: Map<any, any> = new Map();
  screenStep = 0;
  scrollVisible = false;
  credits:number;
  premium:boolean=false;
  signupPayload =    {
    "email": "",
    "phone": "",
    "is_login": false,
    "first_name": "",
    "last_name": "",
    "code": ""
    }
  loginPayload ={
    "email": "",
    "phone": "",
    "is_login": true,
    "first_name": "",
    "last_name": "",
    "code": ""
  }

  constructor(private userApi: UserApi) {
    makeAutoObservable(this);
    makePersistable(this, {name: 'UserStore', properties: ['id', 'isLoggedIn', 'token', 'email', 'info', 'type', 'verified', 'isenterprise' ,'signupPayload', 'loginPayload'], storage: window.localStorage});
  }

  async login(email: string, pwd: string) {
    this.loading = true;
    const payload = {
      email: email,
      pwd: pwd
    }
    const res = await this.userApi.user_login(payload);
    if (res.status === 200) {
      this.id = res.data.id
      this.type = res.data.type
      this.verified = res.data.verified
      this.isenterprise = res.data.isenterprise
      this.info = res.data.info
      this.email = res.data.uname
      this.token = res.data.token
      this.isLoggedIn = true
      this.credits=res.data.availableCredits
      this.premium= res.data.premium_user
    }
    this.loading = false;
    return res
  }

  async loginOrSignup(payloadData: any) {
    this.loading = true;
    const payload = payloadData;
    const res = await this.userApi.request_signup_or_signin(payload);
    if (res.status === 200) {
      this.id = res.data.id
      this.type = res.data.type
      this.verified = res.data.verified
      this.isenterprise = res.data.isenterprise
      this.info = res.data.info
      this.email = res.data.uname
      this.token = res.data.token
      this.isLoggedIn = true
      this.premium= res.data.premium_user
    }
    this.loading = false;
    return res
  }

  async signup(formData) {
    this.loading = true;
    const payload = {
      email: formData.email,
      pwd: formData.password,
      phonenumber:formData.mobile,
    }
    const response = await this.userApi.user_signup(payload);
    return response
  }

  async request_otp(mobile: string,  is_login: boolean ){
   this.loading = true;
   const payload = { phone: mobile, is_login: is_login };
  
    const response = await this.userApi.request_otp(payload);
    return response

  }

  async email_verification(key) {
    this.loading = true;
    const response = await this.userApi.verify_email(key)
    this.loading = false;
    return response;
  }

  async request_reset_pwd(email: string) {
    this.loading = true;
    const payload = {
      email: email
    }
    const res = await this.userApi.request_reset_pwd(payload);
    this.loading = false;
    return res;
  }

  async reset_pwd(code: any, password: string) {
    this.loading = true;
    const payload = {
      code: code,
      pwd: password
    }
    const res = await this.userApi.reset_pwd(payload);
    this.loading = false;
    return res;
  }

  @action
  updateScrolldisplay(step, visbile){
    this.screenStep = step;
    this.scrollVisible = visbile;
  }
  @action
  updateSignUpPayload(key, value) {
    this.signupPayload = {
      ...this.signupPayload,
      [key]: value
    }
  }

  @action
  updateLoginPayload(key, value) {
    this.loginPayload = {
      ...this.loginPayload,
      [key]: value
    }
  }

  
  @action
  clearLogInPayload() {
    this.loginPayload ={
      "email": "",
      "phone": "",
      "is_login": true,
      "first_name": "",
      "last_name": "",
      "code": ""
    }
    
    
  }

  
  @action
  clearSignUpPayload() {
    this.signupPayload = {
      "email": "",
      "phone": "",
      "is_login": false,
      "first_name": "",
      "last_name": "",
      "code": ""
      }
  }


  async clearStoredDate() {
    this.isLoggedIn = false;
    this.id = -1;
    this.token = "";
    this.email = "";
    this.verified = false;
    await clearPersistedStore(this);
  }

  async savePersonalInfo(details: object) {
    this.loading = true;
    const payload = {
      personalDetails: details
    }
    const res = await this.userApi.save_personal_data(payload);
    if (res.status === 200) {

    }
    this.loading = false;
    return res
  }
}
