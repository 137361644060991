import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/use-store';
import { useEffect, useState } from 'react';
import { SideOverlay } from '../../side-overlay';
import TemplateCard from '../../components/templete-card/templete-card';
import templates from '../../pages/sop/templates.json';
import CalenderIcon from '../../icons/calender.svg';
import deleteIcon from '../../icons/delete.svg';
import PencilIcon from '../../icons/pencil.svg';

const fetchTemplates = (sopStore) => {
  let allTemplates = Object.keys(sopStore.templates);
  return allTemplates.map((key) => {
    if (sopStore.selected_text_area.name == key) {
      return sopStore.templates[key].map((eachElement) => {
        return eachElement.map((item, i) => {
          return (
            <TemplateCard
              coinsLeft={item.coinsLeft}
              label={item.label}
              content={item.content}
              footerLabel={item.footerLabel}
              id={item.id}
            />
          );
        });
      });
    }
  });
};

const Text_area = observer((props: any) => {
  const { toolsStore, sopStore } = useStore();
  const [sug, setSug] = useState('');
  const [sopSug, setSopSug] = useState(props.value);

  const template_close_handler = () => {
    sopStore.toggle_show_template();
  };

  useEffect(() => {
    setSopSug(props.value);
    if (
      sopStore.selected_text_area.template_data &&
      props.slot == sopStore.selected_text_area.name
    ) {
      setSopSug(sopSug + sopStore.selected_text_area.template_data);
    }
  }, [sopStore.selected_text_area.template_data, props.value]);

  const textareaHandler = (evt) => {
    if (props.sop) {
      setSopSug(evt.target.value);
      if (props.slot === 'qualifications' || props.slot === 'contributions') {
        sopStore.updateSopJsonDataArr(props.slot, props.id, evt.target.value);
      } else if (props.slot === 'motivation') {
        sopStore.updateSopJsonObj(props.slot, evt.target.value);
      } else {
        sopStore.updateSopJsonData(props.slot, evt.target.value);
      }
    } else {
      setSug(evt.target.value);
      toolsStore.updateComposeObject(props.slot, evt.target.value.split(','));
    }
  };

  const suggestionClickedHandler = (sugg) => {
    setSug(sugg);
    toolsStore.updateComposeObject(props.slot, sugg);
  };

  const handleClick = (slot) => {
    sopStore.set_selected_textarea(slot, '', '');
  };

  return (
    <>
      {props.sop ? (
        <div className="flex flex-col py-2">
          <div className="flex justify-between items-center">
            {props.cardSelected ? (
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className={` ${
                    sopStore.final_output_screen && 'hidden'
                  }  w-[18px] h-[15px] mr-[13px] text-[#000000] cursor-pointer`}
                  onClick={() => {
                    sopStore.updateCardSelected(false);
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                  />
                </svg>
                <label className="text-[#000000] text-[24px] items-center py-2 pr-[14px]">
                  {props.display
                    ? props.display === 'Life Experiences'
                      ? 'Life Experience'
                      : props.display
                    : sopStore.sopjsonData.motivation &&
                      sopStore.sopjsonData.motivation['cardTitle']}
                </label>
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#A033FF"
                  className={`${sopStore.final_output_screen && 'hidden'} w-6 h-6`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                  />
                </svg> */}
              </div>
            ) : (
              <div className="flex items-center">
                <label className="text-[#4E5664] text-base items-center py-2">
                  {props.display}
                </label>
                {/* {props.showInfoIcon && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#A033FF"
                    className={`${sopStore.final_output_screen && 'hidden'} w-6 h-6 ml-3`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                    />
                  </svg>
                )} */}
              </div>
            )}
            {!sopStore.final_output_screen && (
              <div className="flex">
                {props.showDeleteIcon && (
                  <img
                    className="cursor-pointer"
                    onClick={props.onDelete}
                    src={deleteIcon}
                    alt={'remove task'}
                  />
                )}
                {props.showTemplateIcon &&
                <div onClick={() => sopStore.toggle_show_template()}>
                  <img
                    src={CalenderIcon}
                    alt={'remove task'}
                    className="ml-7 cursor-pointer"
                    onClick={() => handleClick(props.slot)}
                  />
                </div>
}
              </div>
            )}
          </div>

          <label
            className={`${
              sopStore.final_output_screen ? 'relative' : ''
            } items-center py-2`}
          >
            <textarea
              value={sopSug}
              onChange={textareaHandler}
              className=" bg-[#FEFEFE] p-2 rounded-lg border border-[#C7CBD4] w-full"
              id={props.slot}
              name={props.slot}
              rows={props.rows}
              cols={props.cols}
              placeholder={props.placeholder}
              style={{ resize: 'none' , color:'black' }}
            ></textarea>
            {sopStore.final_output_screen && props.showpencilIcon && (
              <img
                onClick={() => props.onEditClicked(props.slot,props.id)}
                src={PencilIcon}
                className="cursor-pointer absolute top-[45%] transform -translate-y-1/2 right-5"
              />
            )}
          </label>
        </div>
      ) : (
        <>
          <div className="flex flex-col py-4">
            <label className="relative items-center py-2 font-bold">
              {props.display}
              <textarea
                value={sug}
                onChange={textareaHandler}
                className="block border-2 p-2 rounded-md"
                id={props.title}
                name={props.category}
                rows={props.rows}
                cols={props.cols}
                placeholder={props.placeholder}
              ></textarea>
              {sopStore.final_output_screen && props.showpencilIcon && (
                <img
                  onClick={() => props.onEditClicked(props.slot, props.id)}
                  src={PencilIcon}
                  className="cursor-pointer absolute top-[70%] transform -translate-y-1/2 right-3"
                />
              )}
            </label>
          </div>
          {props.suggestions && (
            <>
              <label className="justify-center py-2 ">Examples</label>
              <div className="p-2">
                {props.suggestions.map((sug) => (
                  <div
                    key={sug}
                    className="my-2 py-1 w-fit px-2 bg-blue-100 text-sm cursor-pointer border rounded-lg flex-shrink object-none"
                    onClick={() => suggestionClickedHandler(sug)}
                  >
                    {sug}
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}

      <SideOverlay
        children={<div>{fetchTemplates(sopStore)}</div>}
        onClose={template_close_handler}
        show={sopStore.showTemplate}
        title="Templates"
        length={templates.length}
      />
    </>
  );
});
export default Text_area;
