import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";
import {useStore} from "../../hooks/use-store";

const Dropdown = observer((props: any) => {
  const {toolsStore} = useStore()

  const valueChangeHandler = (evt) => {
    toolsStore.updateComposeObject(props.slot, evt.target.value)
  }

  useEffect(() => {
    toolsStore.updateComposeObject(props.slot, props.default)
  }, [])

  return (
    <div className='flex flex-col py-4'>
      <label className='py-2 font-bold'>{props.display}</label>
      <select onChange={valueChangeHandler} className='border-2 rounded-md p-2' name={props.display} id={props.display}>
        {Object.keys(props.values).map((keyName) => (
          keyName === props.default ?
            <option key={keyName} value={props.values[keyName]} selected>{keyName}</option> :
            <option key={keyName} value={props.values[keyName]}>{keyName}</option>
        ))}
      </select>
    </div>
  )
})
export default Dropdown
