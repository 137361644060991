// src/FloatingMenuPlugin.tsx
import { jsx } from "react/jsx-runtime";
import { useCallback, useEffect as useEffect2, useRef, useState as useState2 } from "react";
import { createPortal } from "react-dom";
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_NORMAL as NORMAL_PRIORITY,
  SELECTION_CHANGE_COMMAND as ON_SELECTION_CHANGE
} from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { computePosition } from "@floating-ui/dom";

// src/hooks/usePointerInteractions.tsx
import { useEffect, useState } from "react";
function usePointerInteractions() {
  const [isPointerDown, setIsPointerDown] = useState(false);
  const [isPointerReleased, setIsPointerReleased] = useState(true);
  useEffect(() => {
    const handlePointerUp = () => {
      setIsPointerDown(false);
      setIsPointerReleased(true);
      document.removeEventListener("pointerup", handlePointerUp);
    };
    const handlePointerDown = () => {
      setIsPointerDown(true);
      setIsPointerReleased(false);
      document.addEventListener("pointerup", handlePointerUp);
    };
    document.addEventListener("pointerdown", handlePointerDown);
    return () => {
      document.removeEventListener("pointerdown", handlePointerDown);
    };
  }, []);
  return { isPointerDown, isPointerReleased };
}

// src/FloatingMenuPlugin.tsx
var DEFAULT_DOM_ELEMENT = document.body;
function FloatingMenuPlugin({
  element,
  MenuComponent
}) {
  const ref = useRef(null);
  const [coords, setCoords] = useState2(void 0);
  const show = coords !== void 0;
  const [editor] = useLexicalComposerContext();
  const { isPointerDown, isPointerReleased } = usePointerInteractions();
  const calculatePosition = useCallback(() => {
    const domSelection = getSelection();
    const domRange = domSelection?.rangeCount !== 0 && domSelection?.getRangeAt(0);
    if (!domRange || !ref.current || isPointerDown)
      return setCoords(void 0);
    computePosition(domRange, ref.current, { placement: "top" }).then((pos) => {
      setCoords({ x: pos.x, y: pos.y - 10 });
    }).catch(() => {
      setCoords(void 0);
    });
  }, [isPointerDown]);
  const $handleSelectionChange = useCallback(() => {
    if (editor.isComposing())
      return false;
    if (editor.getRootElement() !== document.activeElement) {
      setCoords(void 0);
      return true;
    }
    const selection = $getSelection();
    if ($isRangeSelection(selection) && !selection.anchor.is(selection.focus)) {
      calculatePosition();
    } else {
      setCoords(void 0);
    }
    return true;
  }, [editor, calculatePosition]);
  useEffect2(() => {
    const unregisterCommand = editor.registerCommand(
      ON_SELECTION_CHANGE,
      $handleSelectionChange,
      NORMAL_PRIORITY
    );
    return unregisterCommand;
  }, [editor, $handleSelectionChange]);
  useEffect2(() => {
    if (!show && isPointerReleased) {
      editor.getEditorState().read(() => {
        $handleSelectionChange();
      });
    }
  }, [isPointerReleased, $handleSelectionChange, editor]);
  if (!MenuComponent)
    return null;
  return createPortal(
    /* @__PURE__ */ jsx(
      "div",
      {
        ref,
        "aria-hidden": !show,
        style: {
          position: "absolute",
          top: coords?.y,
          left: coords?.x,
          visibility: show ? "visible" : "hidden",
          opacity: show ? 1 : 0
        },
        children: /* @__PURE__ */ jsx(MenuComponent, { editor, shouldShow: show })
      }
    ),
    element ?? DEFAULT_DOM_ELEMENT
  );
}
export {
  FloatingMenuPlugin
};
