import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useStore } from '../../hooks/use-store';
import { observer } from 'mobx-react-lite';
import { ResumeModalStepUploadCV } from './resume-step-upload';
import { ResumeModalStepAddress } from './resume-step-address';
import Location from '../student-onBoarding/location';
import Education from '../student-onBoarding/education';
import WorkExperience from '../student-onBoarding/work-experience';
import Skills from '../student-onBoarding/skills/skills';
import CertificationLicenses from '../student-onBoarding/certification-licenses/certification-licenses';
import { toJS } from 'mobx';
import UsersName from '../new-profile/personal_details.tsx/users_name';
import { text } from 'stream/consumers';

export const ResumeModalComponent = observer((props) => {
  const { userStore, dashboardStore, resumeStore, userProfileStore,sopProfileSelectionStore } = useStore();
  const { pre, post } = resumeStore.resume_progress_stepper_status();
  const [selectedCard, setSelectedCard] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      try {
        await userProfileStore.get_user_list();
        if (userProfileStore.userProfilejsonData.isFirstLogin && !userStore.isenterprise) {
          resumeStore.show_resume_creation_modal();
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
    
  }, []);

  const checkForSopSelectionModel = () => {
    if (sopProfileSelectionStore.sopSelectionEdit) {
      sopProfileSelectionStore.show_sop_selection_creation_modal();
      sopProfileSelectionStore.setSopSelectionEdit(false);
    }
  };


  return (
    <>
      <Transition.Root show={resumeStore.showResumeCreationModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => {
           resumeStore.hide_resume_creation_modal()
           resumeStore.resetState();
           checkForSopSelectionModel();
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="flex relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-sm "
                  style={{ minWidth: 600, maxHeight: 650 }}
                >
                  <div
                    className={`${userProfileStore.profileScreen ? 'fixed right-[25px] top-[25px] text-[24px] cursor-pointer' : 'resume-modal-close'}`}
                    onClick={() => {
                    
                     resumeStore.hide_resume_creation_modal();
                    // resumeStore.resetState();
                    userProfileStore.get_user_profile_name_id_list();
                     checkForSopSelectionModel();
                  
                    }}
                  >
                    <i className="fa-solid fa-xmark" />
                  </div>
                  <div className="resume-modal flex flex-col grow">
                    <div className="modal-header flex flex-row flex-no-wrap w-full items-center px-2 justify-between py-5">
                      <div className={`${userProfileStore.profileScreen && 'hidden'} text-xs text-gray-500 mr-0`}>
                        {resumeStore.resume_progress_get_current_state()}
                      </div>
                      <div
                        className={` ${userProfileStore.profileScreen && 'flex justify-center w-full'}`}
                        style={{
                          fontWeight: 500,
                          fontSize: '24px',
                          lineHeight: '32px',
                          color: '#3C424C',
                        }}
                      >
                        {resumeStore.current_step['name']}
                      </div>
                      <div
                        className={`${userProfileStore.profileScreen && 'hidden'} uppercase text-[#A033FF] cursor-pointer pr-8`}
                        onClick={() => {
                          resumeStore.backButton = false;
                          if (resumeStore.resume_progress_is_last_step()) {
                            resumeStore.hide_resume_creation_modal();

                          } else {
                            resumeStore.resume_progress_nav_next();
                          }

                          if(!resumeStore.showResumeCreationModal){
                            checkForSopSelectionModel();
                          }
                          if (resumeStore.resume_progress_is_last_step() ) {
                            resumeStore.hide_resume_creation_modal();
                           // resumeStore.resetState();
                          }
                          userProfileStore.get_user_profile_name_id_list();
                        }}
                      >
                      { resumeStore.current_step_num ==0 || resumeStore.current_step_num == 1 ? '' :'Skip'}
                      </div>
                    </div>
                    <div className="modal-stepper">
                      <nav aria-label="Progress">
                        <ol role="list" className="space-y-4 md:flex md:space-x-1 md:space-y-0">
                          {Array.from(Array(pre), (e, i) => {
                            return (
                              <li className="md:flex-1">
                                <a className="group flex flex-col border-l-4 border-[#5EAEFF] py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4" />
                              </li>
                            );
                          })}

                          <li className="md:flex-1">
                            <a
                              className="flex flex-col border-l-4 border-[#5EAEFF] py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                              aria-current="step"
                            />
                          </li>

                          {Array.from(Array(post), (e, i) => {
                            return (
                              <li className="md:flex-1">
                                <a className="group flex flex-col border-l-4 border-gray-500 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4" />
                              </li>
                            );
                          })}
                        </ol>
                      </nav>
                    </div>

                    <div
                      className={`${
                        resumeStore.current_step_num !== 5 &&
                        'flex flex-col justify-center items-center '
                      }modal-body grow`}
                      style={{ minHeight: 500, maxHeight: 590 }}
                    >
                      {resumeStore.current_step_num == 1 ? (
                        <ResumeModalStepUploadCV
                          setSelectedCard={setSelectedCard}
                          selectedCard={selectedCard}
                        />
                      ) : null}
                      {/* {resumeStore.current_step_num == 1 ? <ResumeModalStepAddress/> : null} */}
                      {resumeStore.current_step_num == 0?  <UsersName />: null}
                      {resumeStore.current_step_num == 2 ? <Location /> : null}
                      {resumeStore.current_step_num == 3 ? <Education /> : null}
                      {resumeStore.current_step_num == 4 ? <WorkExperience /> : null}
                      {resumeStore.current_step_num == 5 ? <Skills /> : null}
                      {resumeStore.current_step_num == 6 ? <CertificationLicenses /> : null}
                    </div>
                    <div
                      className={` ${
                        userProfileStore.profileScreen ? 'justify-center' : 'justify-around'
                      } modal-footer  flex flex-row items-center py-3 border-t-2 border-gray-200 `}
                    >
                      <span className="mr-0">
                        <div>
                          <button
                            className={`${
                              userProfileStore.profileScreen && 'hidden'
                            } uppercase  rounded-lg px-12 py-2 text-center text-[14px] leading-[18px] w-fit border border-solid border-[#737E93] rounded-md p-4 m-[-4px] ${
                               !resumeStore.navigate &&
                              resumeStore.current_step_num === 0 &&
                              !resumeStore.continueClicked
                                ? 'bg-[#F8F9FA] cursor-not-allowed text-[#737E93] h-10 w-24 rounded-[4px]  flex items-center justify-center'
                                : resumeStore.backButton||(!resumeStore.backButton && resumeStore.current_step_num==1 && !resumeStore.navigate)||(resumeStore.current_step_num === 1 &&userProfileStore.userProfilejsonData.user_name!=''  && resumeStore.upload_screen=='initial-choice' && selectedCard !== ''  )
                                ? 'border ml-6 h-10 w-24 rounded-[4px] text-white border-purple-500 bg-[#737E93] cursor-pointer flex items-center justify-center'
                                : 'border ml-6 h-10 w-24 rounded-[4px] text-purple-600 border-purple-500 bg-purple-50 cursor-pointer flex items-center justify-center'
                            }`}
                            disabled={

                                 resumeStore.backButton || (!resumeStore.backButton && resumeStore.current_step_num==1 && !resumeStore.navigate)||
                              (resumeStore.current_step_num === 0 && !resumeStore.navigate)||
                              (resumeStore.current_step_num===1 && userProfileStore.userProfilejsonData.user_name!='' && resumeStore.upload_screen=='initial-choice'  && selectedCard !== '' )
                            }
                            onClick={() => {
                              if (resumeStore.current_step_num === 2 && resumeStore.navigate ) {
                                resumeStore.setUploadScreen('upload-doc');
                                resumeStore.setContinueClicked(true);
                                resumeStore.resume_progress_nav_previous();
                              } else if (
                                resumeStore.current_step_num === 1 &&
                                resumeStore.navigate
                              ) {
                                resumeStore.setUploadScreen('initial-choice');
                                resumeStore.setContinueClicked(false);
                                resumeStore.setNavigate(false);
                                setSelectedCard('');
                              } else {
                                resumeStore.resume_progress_nav_previous();
                                resumeStore.setContinueClicked(false);
                                setSelectedCard('');
                              }
                              resumeStore.setContinueButton(false);
                            }}
                          >
                            Back
                          </button>
                        </div>
                      </span>
                      <span
                        className={` ${
                          userProfileStore.profileScreen ? 'mx-[90px] w-full' : 'ml-40'
                        }`}
                      >
                        <div>
                          {!userProfileStore.profileScreen ? (
                            <button
                              className={`uppercase text-white rounded-lg px-12 py-2 text-center text-[14px] leading-[18px] w-fit m-[-4px]  ${
                           userProfileStore.loading || (resumeStore.current_step_num === 1 &&
                                  !resumeStore.navigate &&
                                selectedCard === '') || (resumeStore.upload_screen == 'upload-doc' && resumeStore.navigate && resumeStore.continueButton==true)||                
                                (resumeStore.current_step_num === 0 &&  userProfileStore.userProfilejsonData.user_name === '' )
                                  ? ' border h-10 w-26 rounded-[4px]  flex items-center justify-center cursor-not-allowed bg-[#737E93]'
                                  : 'border h-10 w-26 rounded-[4px]  flex items-center justify-center bg-[#A033FF] text-white '
                              }`}
                              disabled={
                                userProfileStore.loading ||     (!userProfileStore.profileScreen &&
                                  resumeStore.current_step_num === 1 &&
                                  !resumeStore.navigate &&
                                  selectedCard === '') || (resumeStore.upload_screen == 'upload-doc' && resumeStore.navigate && resumeStore.continueButton==true)|| 
                               ( resumeStore.current_step_num === 0 && userProfileStore.userProfilejsonData.user_name === '' ) 
                              }
                              onClick={() => {
                              
                                
                                // To set state of continue button
                                if(resumeStore.current_step_num>0){
                                  resumeStore.setContinueClicked(true);

                                }
                                if(resumeStore.current_step_num ===0 ){
                                  userProfileStore.get_user_list();
                                  resumeStore.setUploadScreen('initial-choice');
                                }
                                
                              
                                  if (resumeStore.current_step_num !==0 ) {
                                 
                                    userProfileStore.update_profile('');
                                   
                                 }
                               
                                if (resumeStore.resume_progress_is_last_step() ) {
                                  resumeStore.hide_resume_creation_modal();
                                 // resumeStore.resetState();
                                }
                                // To call next nav when we click the continue button of when we select cambri resume upload card
                                else if (resumeStore.upload_screen == 'initial-choice') {
                                  resumeStore.resume_progress_nav_next();
                                }
                                // To call next nav when we click the continue button of when we selected the upload resume card in that after uploading the resume
                                else if (resumeStore.navigate) {
                                  resumeStore.resume_progress_nav_next();
                                }

                                if(!resumeStore.showResumeCreationModal){
                                  checkForSopSelectionModel();
                                }
                                if(resumeStore.current_step_num==6){
                                  userProfileStore.get_user_profile_name_id_list()
                                }
                            if(resumeStore.current_step){
                             resumeStore.backButton=false
                            }
                            if(resumeStore.current_step){
                              setSelectedCard('')
                            }
                              }}
                            >
                              {/* To set the continue button for add resume screen and save next button for cambri resume steps screens  */}
                              {resumeStore.current_step_num == 0 || resumeStore.current_step_num == 1  ? 'CONTINUE' : 'SAVE AND NEXT'}
                            </button>
                          ) : (
                            <button
                              onClick={async () => {
                                 await userProfileStore.update_profile(resumeStore.hide_resume_creation_modal());
                                // resumeStore.resetState();
                                 checkForSopSelectionModel();
                                 if(userProfileStore.isFetchUsersData){
                                  await userProfileStore.get_user_profile_name_id_list();
                                  userProfileStore.FetchUsersData(false)
                                 }
                               }}
                              className="bg-[#A033FF] uppercase text-white rounded-lg px-12 py-2 text-center text-[14px] leading-[18px] w-full m-[-4px]"
                            >
                               SAVE
                           </button>

                          )}
                        </div>
                      </span>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
});
