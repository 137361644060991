import React, { useEffect,useState } from 'react';
// import Header from "components/Header";
import Tree from './tree';
import { useStore } from '../../hooks/use-store';
// import ExternalInfo from "components/ExternalInfo";

const TreeList = () => {
  const { newContentStore,sopStore,uKSopStore } = useStore();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    load_contents();
    sopStore.setIsFromCreateSop(false);
    uKSopStore.setIsFromCreateSop(false);
  }, []);

  const load_contents = async () => {
    setLoading(true);
    await newContentStore.get_all_contents();
    setLoading(false);
  };

  return (
    <> 
      <div className="flex w-full">
        <div className="flex-col w-full">
            <div className="flex w-full">
              <div className="col-lg-8 text-left text-dark w-full">
                <Tree data={newContentStore.contents} />
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default TreeList;
