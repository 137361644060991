import {observer} from "mobx-react-lite";
import React from "react";
import {Editor} from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useStore} from "../../hooks/use-store";
import {safe_get} from "../utils";
import {Loading} from "../../components/Loading";
import {toast} from "react-toastify";

export const DocumentEditor = observer((props) => {
  const {documentStore} = useStore()

  const onContentStateChange = (data) => {
    documentStore.update_active_document_json(data)
  }

  if (documentStore.loading) return <Loading/>

  const saveDocument = async () => {
    const res = await documentStore.save_active_document()
    if (res.status === 200) {
      toast('Document saved successfully')
    } else {
      toast.error(res.data)
    }
  }

  return (
    <>
      <div className='flex flex-row flex-wrap items-center'>
        <div className='text-xl uppercase font-bold' style={{color: '#2E86C1'}}>{safe_get(documentStore.active_document, 'name', '')}</div>
        <div className='ml-auto'>
          <button onClick={saveDocument} className='py-2 px-16 my-2 bg-green-200 text-black text-bold rounded-lg uppercase'>Save</button>
        </div>

      </div>
      <div key={`container_${documentStore.editor_id}`}>
        <Editor
          key={documentStore.editor_id}
          className='bg-white'
          textAlignment={'left'}
          toolbarClassName="toolbarClassName"
          initialContentState={documentStore.get_active_document_json()}
          wrapperClassName="wrapperClassName"
          editorClassName="bg-white border-2 border-gray-500 border-rounded-lg leading-6 px-2 text-sm min-h-code-50"
          onContentStateChange={onContentStateChange}
        />
      </div>
    </>
  )
})
