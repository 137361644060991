import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/use-store';
import { safe_get } from '../utils';
import ProfileCustomTextField from '../../components/profile-text-field/profile-text-field';

const Location = observer(() => {
  const { userProfileStore } = useStore();

  const handleChange = (event, keyValue) => {
    userProfileStore.updateUserProfileJsonDataObj('location', keyValue, event.target.value);
  };

  const locationFields = [
    {
      name: 'streetAddress',
      label: 'Street Address',
      keyValue: 'street_address',
      value: safe_get(userProfileStore.userProfilejsonData, 'location.street_address', ''),
    },
    {
      name: 'city',
      label: 'City',
      keyValue: 'city',
      value: safe_get(userProfileStore.userProfilejsonData, 'location.city', ''),
    },
    {
      name: 'state',
      label: 'State',
      keyValue: 'state',
      value: safe_get(userProfileStore.userProfilejsonData, 'location.state', ''),
    },
    {
      name: 'postCode',
      label: 'Post Code',
      keyValue: 'pincode',
      value: safe_get(userProfileStore.userProfilejsonData, 'location.pincode', ''),
    },
  ];

  return (
    <div className="overflow-y-auto px-24">
      {locationFields.map((field) => (
        <ProfileCustomTextField
          key={field.name}
          placeholder="Enter"
          onChange={(e) => handleChange(e, field.keyValue)}
          name={field.name}
          type="text"
          label={field.label}
          slot="location"
          keyValue={field.keyValue}
          value={field.value}
        />
      ))}
    </div>
  );
});

export default Location;
