import apiSauce from "./api-sauce";

export class PaymentApi {

    async createOrder(payload): Promise<any> {
        return await apiSauce.apisauce.post("payments/create-order",JSON.stringify(payload))
    }

    async paymentSuccess(payload): Promise<any> {
        return await apiSauce.apisauce.post("payments/payment-success",JSON.stringify(payload))
    }

    async getPaymentCardDetails(): Promise<any> {
        return await apiSauce.apisauce.get("payments/get_plans")
    }

    async createOrder_2 (payload): Promise<any> {
        return await apiSauce.apisauce.post("paymentsv1/create-order",JSON.stringify(payload))
    }

}
