import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useStore } from '../../../hooks/use-store';
import { observer } from 'mobx-react-lite';

const InputCalender = observer((props: any) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const { userProfileStore } = useStore();

  useEffect(() => {
    if (props.value) {
      const convertDateFormat = (dateString: string | Date | null): string | Date | null => {
        if (typeof dateString === 'string' && dateString.includes('/')) {
          let parts = dateString.split('/');
          let convertedDate = parts[1] + '/' + parts[0] + '/' + parts[2];
          return convertedDate;
        } else if (dateString instanceof Date) {
          return dateString; // No need to convert, already a Date object
        }
  
        // If dateString is not a string with '/', and not a Date object, return null or handle accordingly.
        return null; // or throw an error, or return a default value, depending on your use case.
      };
  
      let originalDate = props.value;
      let convertedDate = convertDateFormat(originalDate);
  
      // Add a check to ensure convertedDate is a string before creating a new Date instance
      const date = typeof convertedDate === 'string' ? new Date(convertedDate) : convertedDate;
  
      // Convert props.value to a Date object
      if (date instanceof Date && !isNaN(date.getTime())) {
        setSelectedDate(date);
      }
    }
  }, []);
  

  const handleDateChange = (date: Date | null) => {
    if (date && isDateValid(date)) {
      setSelectedDate(date);

      if (props.slot === 'location') {
        userProfileStore.updateUserProfileJsonDataObj(props.slot, props.keyValue, date);
      } else if (props.slot ===  'education'||'work_experience') {
        userProfileStore.updateUserJsonDataArr(props.slot, props.itemId, props.keyValue, date);
      }
    }
  };

  const isDateValid = (date: Date | null): boolean => {
    return date !== null && !isNaN(date.getTime());
  };

  return (
    <div className="date-picker-container pb-5 flex flex-col">
      <label className="mb-2 left-0 -top-3.5 text-[16px] leading-[22px] text-[#737E93] peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
        {props.label}
      </label>
      <DatePicker
        readOnly={props.disabled}
        selected={selectedDate}
        onChange={handleDateChange}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dateFormat="dd/MM/yyyy"
        dropdownMode="select"
        className="my-date-picker bg-white"
        calendarClassName="my-date-picker-calendar"
        placeholderText={props.placeholder}
      />
    </div>
  );
});

export default InputCalender;
