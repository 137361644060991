import { observer } from "mobx-react-lite";

export const PlusButton = observer((props:any) => {
  return (
    <div onClick={()=>props.handleClick(props.name)} className={`ml-2 border border-[#A033FF] rounded-lg text-[#A033FF] p-1.5 cursor-pointer h-10 ${props.enabled ? 'opacity-50 cursor-not-allowed' : ''}`}>
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
        </svg>
      </span>
    </div>
  );
});
