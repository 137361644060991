import {lazy, Suspense, useEffect} from 'react';
import {BrowserRouter, Route, Routes, useNavigate} from 'react-router-dom';
import {Loading} from "./components/Loading";
import {apiInterceptor} from "./api/api-interceptor";
import {useStore} from "./hooks/use-store";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {AppShell} from "./components/app/Shell";
import {AuthenticatedLayout} from "./layout/authenticated";
import {DocumentEditorWrapper} from "./pages/document_editor/document_editor_wrapper";
import {ResumeModalComponent} from "./pages/resume/resume-modal";
import { CambriRichEditor } from './features/editor/editor-root';


/***************** LOAD ROUTES *****************/
// Set up all routers as lazy routes. THis is important because when the app loads, we want the landing page to split load quickly for SEO purposes.
// Hence make it default to setup all routes as lazy routes.

const LandingPage = lazy(() => import('./pages/home_page/index'))
const SignIn = lazy(() => import('./pages/authentication/sign_in'))
const RequestResetPwd = lazy(() => import('./pages/authentication/request_reset_pwd'))
const ResetPwd = lazy(() => import('./pages/authentication/reset_pwd'))
const SignUp = lazy(() => import('./pages/authentication/sign_up'))
const DashboardOld = lazy(() => import('./pages/dashboard-old/dashboard'))
const Dashboard = lazy(() => import('./pages/dashboard/dashboard'))
const VerifyEmail = lazy(() => import('./pages/authentication/verify-email'))
// const Profile = lazy(() => import('./pages/profile/profile'))
const ToolsList = lazy(() => import('./pages/tools/gen_tools_category'))
const Compose = lazy(() => import('./pages/tools/compose'))
const TextEditorScreen = lazy(() => import('./pages/tools/text-editor-screen'))
const TemplateEditor = lazy(() => import('./pages/tools/template-editor/template_editor'))
const Sop = lazy(() => import('./pages/sop/sop'))
const FinalOutput = lazy(() => import('./pages/sop/final_output_screen/final_output_screen'))
const UKFinalOutput = lazy(() => import('./pages/uk_sop/final_output_screen/final_output_screen'))
const CanadaFinalOutput = lazy(() => import('./pages/canada_sop/final_output_screen/final_output_screen'))

const Login = lazy(() => import('./pages/LoginPage/LoginPage'))
const NotFound = lazy(() => import('./pages/LoginPage/NotFound'))
const Profile = lazy(() => import('./pages/new-profile/profile'))
const OnBoard = lazy(() => import('./pages/student-onBoarding/student-onBoarding'))
const PaymentsPage = lazy(()=>import('./pages/new-profile/payments/billing'))
const TransactionSummary= lazy(()=>import('./pages/new-profile/payments/transactionSummary'))
const UkSop = lazy(() => import('./pages/uk_sop/uk_sop'))
const CanadaSop = lazy(() => import('./pages/canada_sop/canada_sop'))

const ResetPassword = lazy(() => import('./pages/LoginPage/ResetPassword'))
const ChangePassword = lazy(() => import('./pages/LoginPage/ChangePassword'))
const Tools = lazy(() => import('./pages/tools/new_tools_category'))
const Otp = lazy(() => import('./pages/LoginPage/OtpScreen/OtpScreen'))
const Library = lazy(() => import('./pages/dashboard/new-folder-content'))
const SopFile = lazy(() => import('./pages/sop-file/sop-file'))
const AdminTemplateEditor = lazy(() => import('./pages/admin/admin-template-editor'))
const AdminQuestionEditor = lazy(() => import('./pages/admin/admin-question-editor'))
const TermsAndConditions = lazy(() => import('./pages/profile/razer/TermsAndConditions'))
const Contactus = lazy(() => import( './pages/profile/razer/contactus'))
const CancellationandRefundPolicy = lazy(() => import( './pages/profile/razer/CancellationandRefundPolicy'))
const PrivacyPolicy = lazy(() => import( './pages/profile/razer/PrivacyPolicy'))
const ShippingandDeliveryPolicy = lazy(() => import( './pages/profile/razer/ShippingandDeliveryPolicy'))
/***********************************************/


export const ShellWrapper = ({component: Component, ...rest}) => {

  const {userStore,sopStore,dashboardStore} = useStore();
  const navigate = useNavigate()
  const signout = () => {
    userStore.clearStoredDate()
    navigate('/login')
  }

  useEffect(() => {
    if(!userStore.isLoggedIn){
      sopStore.setFinalOutputScreen(false)
      navigate('/login')
      return
    }

    window.addEventListener(
      "token-expired",
      (e) => {
        console.log('Token Expired event triggerred', e)
        signout()
      },
      false
    );
  }, [])

  return (
    <>
      <AuthenticatedLayout>
        <Component {...rest} />

        <ResumeModalComponent />
      </AuthenticatedLayout>
    </>
  );
};

export const PublicRoute = ({component: Component, ...rest}) => {
  return <Component {...rest} />;
};

export const App = () => {

  const {userStore} = useStore();

  apiInterceptor(userStore);

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading/>}>
        <Routes>
          <Route index element={<Login/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/onboard" element={<OnBoard/>}/>
          <Route path="/sopfile" element={<ShellWrapper component={SopFile}/>}/>
          <Route path="/library" element={<ShellWrapper component={Library}/>}/>
          <Route path="/signupnew" element={<Login/>}/>
          <Route path="/otp/:id" element={<Otp/>}/>
          {/* <Route path="/tools" element={<Tools/>}/> */}
          <Route path="/resetpassword" element={<ResetPassword/>}/>
          <Route path='/changepassword' element={<ChangePassword/>}/>
          <Route path="/shell" element={<AppShell/>}/>
          <Route path="/signin" element={<SignIn/>}/>
          <Route path="/requestresetpwd" element={<RequestResetPwd/>}/>
          <Route path="/pwdreset" element={<ResetPwd/>}/>
          <Route path="/resetpwd" element={<ResetPwd/>}/>
          <Route path="/signup" element={<SignUp/>}/>
          <Route path="/verifyemail" element={<VerifyEmail/>}/>
          <Route path="/tools" element={<ShellWrapper component={Tools}/>}/>
          <Route path="/dashboard-old" element={<ShellWrapper component={DashboardOld}/>}/>
          <Route path="/dashboard" element={<ShellWrapper component={Tools}/>}/>
          <Route path="/doc/:id" element={<ShellWrapper component={DocumentEditorWrapper}/>}/>
          <Route path="/profile" element={<ShellWrapper component={Profile} selectedItem='Account'/>}/>
          <Route path="/profile/account" element={<ShellWrapper component={Profile} selectedItem='Account'/>}/>
          <Route path="/profile/usage" element={<ShellWrapper component={Profile} selectedItem='Usage'/>}/>
          {/* <Route path="/profile/billing" element={<ShellWrapper component={Profile} selectedItem='Billing'/>}/> */}
          <Route path="/profile/managetools" element={<ShellWrapper component={Profile} selectedItem='Manage Tools'/>}/>
          <Route path="/toolslist/compose/:id" element={<ShellWrapper component={Compose}/>}/>
          <Route path="/toolslist" element={<ShellWrapper component={ToolsList}/>}/>
          <Route path="/generate" element={<ShellWrapper component={TextEditorScreen}/>}/>
          <Route path="/tool/edit/:id" element={<ShellWrapper component={TemplateEditor}/>}/>
          <Route path="/sop" element={<ShellWrapper component={Sop}/>}/>
          <Route path="/sop/:id" element={<ShellWrapper component={Sop}/>}/>
          <Route path="/sop/:id/:folderid" element={<ShellWrapper component={FinalOutput}/>}/>
          <Route path="/admintemplateeditor" element={<ShellWrapper component={AdminTemplateEditor}/>}/>
          <Route path="/adminquestioneditor" element={<AdminQuestionEditor/>}/>
          <Route path="/termsandconditions" element={< TermsAndConditions />}/>
          <Route path="/contactus" element={< Contactus />} />
          <Route path="/CancellationandRefundPolicy" element={< CancellationandRefundPolicy />} />
          <Route path="/PrivacyPolicy" element={<  PrivacyPolicy />} />
          <Route path="/ShippingandDeliveryPolicy" element={< ShippingandDeliveryPolicy />} />
          <Route path="*" element={<NotFound/>}/>
          <Route path="/profile/billing" element={<ShellWrapper component={Profile} selectedItem='Billing'/>}/>
          <Route path="/paymentspage" element={<PaymentsPage/>}/>
          <Route path="/transactionSummary" element={<TransactionSummary/>} />
          <Route path="/editor" element={<CambriRichEditor/>} />
          <Route path="/uksop" element={<ShellWrapper component={UkSop}/>}/>
          <Route path="/uksop/:toolid" element={<ShellWrapper component={UkSop}/>}/>
          <Route path="/uksop/:id/:folderid" element={<ShellWrapper component={UKFinalOutput}/>}/>
          <Route path="/canadasop" element={<ShellWrapper component={CanadaSop}/>}/>
          <Route path="/canadasop/:id/:folderid" element={<ShellWrapper component={CanadaFinalOutput}/>}/>
          <Route path="/canadasop/:toolid" element={<ShellWrapper component={CanadaSop}/>}/>
        </Routes>
      </Suspense>
      <ToastContainer/>
    </BrowserRouter>
  );
};
