import React, { useState, useEffect, useRef } from 'react';
import { useStore } from '../../hooks/use-store';
import { toJS } from 'mobx';
import { TableCell } from 'mdast';
import folderCloseIcon from '../../icons/folderClose.svg';
import fileIcon from '../../icons/file.svg';

const TableBody = ({ item_click, item, renameDoc, deleteDoc, id }) => {
  const { newContentStore } = useStore();
  const [popover, setPopover] = useState(false);
  const containerRef = useRef<HTMLTableCellElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    const { current: wrapper } = containerRef;
    if (wrapper && !wrapper.contains(event.target as Node)) {
      setPopover(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <tr className="border-b border-[#E3E5E9]">
        <th
          onClick={() => item_click(item)}
          scope="row"
          className="flex items-center px-6 py-4 font-medium text-[#4E5664] whitespace-nowrap cursor-pointer"
        >
          {item.isfile ? (
            <img className="cursor-pointer mr-4" src={'/sop_file.svg'} />
          ) : (
            <img className="cursor-pointer mr-4 w-4 h-4" src={folderCloseIcon} />
          )}{' '}
          {item.name}
        </th>
        <td className="px-6 py-4 cursor-pointer">{item.isfile ? 'File' : 'Folder'}</td>
        <td className="px-6 py-4">{item.modified}</td>
        <td
          ref={containerRef}
          data-popover-target="popover-bottom"
          data-popover-placement="bottom"
          onClick={() => {
            setPopover(!popover);
          }}
          className="px-6 py-4 cursor-pointer flex flex-col items-center"
        >
          <i className="fa-light fa-ellipsis-stroke-vertical text-[20px]"></i>
          {popover && item.id == id && (
            <div
              data-popover
              id="popover-click"
              role="tooltip"
              style={{boxShadow:'0px 1px 10px rgba(0, 0, 0, 0.15)'}}
              className="absolute w-24 z-[99] mt-7 right-[100px] text-sm text-gray-500 duration-300 bg-[#FEFEFE] rounded-lg"
            >
              <div className="px-3 py-2 rounded-t-lg">
                <h3
                  className="font-semibold p-1 text-[#4E5664]"
                  onClick={(event) => {
                    renameDoc(event, item);
                    setPopover(!popover);
                  }}
                >
                  Rename
                </h3>
                <h3
                  className="font-semibold p-1 text-[#FF495C]"
                  onClick={(event) => {
                    deleteDoc(event, item);
                    setPopover(!popover);
                  }}
                >
                  Delete
                </h3>
              </div>
            </div>
          )}
        </td>
      </tr>
    </>
  );
};

export default TableBody;
