import { TaskStore } from './task-store';
import { TaskApi } from '../api/task-api';
import {UserStore} from "./user-store";
import {UserApi} from "../api/user-api";
import {ContentStore} from "./content-store";
import {ContentApi} from "../api/content-api";
import {ToolsStore} from "./tools-store";
import { NewToolsStore } from './new-tool-store';
import {NewContentStore} from './new-content-store';
import {ToolsApi} from "../api/tools-api";
import {DocumentStore} from "./document-store";
import {DocumentApi} from "../api/document-api";
import {SopStore} from "./sop-store";
import {SopApi} from "../api/sop-api";
import {DashboardStore} from "./dashboard-store";
import { PaymentStore } from './payment-store';
import {DashboardApi} from "../api/dashboard-api";
import { PaymentApi } from '../api/payment-api';
import {ResumeAPI} from "../api/resume-api";
import {ResumeStore} from "./resume-store";
import { UserProfileApi } from './../api/user-profile-api';
import { UserProfileStore } from './user-profile-store';
import {AdminStore } from './admin-store';
import { AdminApi } from './../api/admin-api';
import { UKSopStore } from './uk-sop-store';
import {UKSopApi} from "../api/uk-sop-api";
import { CanadaSopStore } from './canada-sop-store';
import {CanadaSopApi} from "../api/canada-sop-api";
import { SopProfileSelectionStore } from './sop-profile-selection_store';

export const RootStore = {
  taskStore: new TaskStore(new TaskApi()),
  userStore: new UserStore(new UserApi()),
  contentStore: new ContentStore(new ContentApi()),
  newContentStore: new NewContentStore(new ContentApi()),
  toolsStore: new ToolsStore(new ToolsApi()),
  newToolsStore: new NewToolsStore(new ToolsApi()),
  documentStore: new DocumentStore(new DocumentApi()),
  sopStore: new SopStore(new SopApi()),
  uKSopStore: new UKSopStore(new UKSopApi()),
  canadaSopStore: new CanadaSopStore(new CanadaSopApi()),
  dashboardStore: new DashboardStore(new DashboardApi()),
  paymentStore: new PaymentStore(new PaymentApi()),
  userProfileStore: new UserProfileStore(new UserProfileApi()),
  resumeStore: new ResumeStore(new ResumeAPI()),
  adminStore: new AdminStore(new AdminApi()),
  sopProfileSelectionStore : new SopProfileSelectionStore(new UserProfileApi())
};
