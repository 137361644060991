import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import Gen_tools_category from "../tools/gen_tools_category";
import {DocumentEditor} from "./document_editor";
import {useStore} from "../../hooks/use-store";
import {useParams} from "react-router-dom";
import Compose from "../tools/compose";
import Current_History_Details from "../tools/history/current_history_details";
import {toast} from "react-toastify";
import {Loading} from "../../components/Loading";

export const DocumentEditorWrapper = observer((props) => {
  const {documentStore, toolsStore} = useStore()
  const params: any = useParams()

  useEffect(() => {
    fetchDocumentDetails()
  }, [])

  useEffect(() => {
    fetchHistory()
  }, [toolsStore.compose_response])

  const fetchDocumentDetails = async () => {
    const id = parseInt(params.id)
    if (id > 0) {
      const resp = await documentStore.get_document_details(id)
      if (resp.status !== 200) {
        toast.error(resp.data)
      }
    }
  }

  const fetchHistory = async () => {
    const res = await toolsStore.getComposeHistory(0, 5)
    if (res.status === 200) {
      toolsStore.set_current_history_details(res.data)
    } else {
      toast.error(res.data)
    }
  };

  return (
    <div className="flex lg:flex-row sm:flex-col">
      <div className="lg:w-2/5 sm:w-full p-2">
        <Gen_tools_category/>

        <div className='py-2'/>
        {toolsStore.selectedToolId > 0 ? <Compose/> : ''}

        <div className='py-2'/>
        {toolsStore.current_history_details.length > 0 ? <Current_History_Details/> : ''}
      </div>

      <div className="lg:w-3/5 sm:w-full p-2">
        {documentStore.loading ? <Loading/> :
          (documentStore.active_document ? <DocumentEditor/> : '')}
      </div>
    </div>
  )
})
