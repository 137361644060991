import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {useStore} from "../../hooks/use-store";
import _ from 'lodash';
import GenToolsList from "./gen_tools_list";
import {safe_get} from "../utils";
import {useNavigate} from "react-router-dom";

const Gen_tools_category = observer((props: any) => {
    const {toolsStore} = useStore()
    const [searchQuery, setSearchQuery] = useState('')
    const path = window.location.pathname
    const navigate = useNavigate();

    useEffect(() => {
      getToolsForSearchQuery()
    }, [])

    const searchQueryHandler = (evt) => {
      setSearchQuery(evt.target.value)
    }

    const getToolsForSearchQuery = async () => {
      let res;
      if (path === '/profile/managetools') {
        res = await toolsStore.getMyToolsList(searchQuery)
      } else {
        res = await toolsStore.getToolsList(searchQuery)
      }
      if (res.status === 200) {
        toolsStore.setAllToolsDetails(res.data)
      } else {
        toast.error(res.data)
      }
    }

    const expandHandler = () => {
      toolsStore.toggle_content_composer_expanded_status()
    }

    const addNewToolHandler = async () => {
      const id = await toolsStore.add_new_tool()
      if (id > 0) {
        navigate(`/tool/edit/${id}`)
      }
    }

    const onEnter = (event) => {
      if (event.keyCode === 13) {
        getToolsForSearchQuery()
      }
    }

    return (
      <>
        <div className='p-4 rounded-lg bg-gray-100 border-gray-100 border-2'>
          <div className='flex flex-row justify-between items-center cursor-pointer' onClick={expandHandler}>
            <div className='text-lg font-bold uppercase text-gray-600'>Content Composers</div>
            {toolsStore.content_composer_expanded_status ?
              <i className="fa fa-solid fa-caret-up mr-4 fs-26 cursor-pointer"/> :
              <i className="fa fa-solid fa-caret-down mr-4 fs-26 cursor-pointer"/>
            }
          </div>
          {toolsStore.content_composer_expanded_status &&
            <>
              <div className="input-group relative flex items-stretch w-full mb-4">
                <input
                  type="search"
                  className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="button-addon2"
                  onKeyUp={onEnter}
                  onChange={searchQueryHandler}/>
                <button
                  className="btn inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center"
                  type="button"
                  id="button-addon2"
                  onClick={getToolsForSearchQuery}>
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                  </svg>
                </button>
              </div>
              {path === '/profile/managetools' && <div className='ml-auto w-fit'>
                <button
                  type="button"
                  onClick={addNewToolHandler}
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                >
                  Add New Tool
                </button>
              </div>}
              {
                Object.keys(safe_get(toolsStore.allToolsDetails, 'category_map', {})).map((cat_name) => {
                  const cat_items = safe_get(toolsStore.allToolsDetails, `category_map.${cat_name}`, [])

                  return (
                    <div key={cat_name}>
                      <div className='flex flex-row items-center cursor-pointer' onClick={() => {
                        toolsStore.toggle_category_expanded_status(cat_name)
                      }}>
                        {toolsStore.get_category_expanded_status(cat_name) ? (
                          <i className="fa fa-solid fa-caret-down mr-4 fs-22"/>
                        ) : (
                          <i className="fa fa-solid fa-caret-right mr-4 fs-22"/>
                        )}
                        <span className='text-lg font-bold uppercase text-gray-600'>{cat_name}</span>
                        <span className='fs-22 text-gray-500'>&nbsp;({cat_items.length})</span>
                      </div>
                      <div className='p-4'>
                        {toolsStore.get_category_expanded_status(cat_name) ? (
                          <GenToolsList activeToolDetails={toolsStore.allToolsDetails['category_map'][cat_name]}/>
                        ) : null}
                      </div>
                    </div>
                  )
                })
              }
              {!_.isEmpty(toolsStore.allToolsDetails) && !(toolsStore.allToolsDetails['count'] > 0) ?
                <div>No results found</div>
                : ''}
            </>
          }
        </div>
      </>
    )
  }
)
export default Gen_tools_category
