import {observer} from "mobx-react-lite";
import React, {useEffect, useRef, useState} from "react";
import {useStore} from "../../hooks/use-store";
import {toast} from "react-toastify";
import Dropdown from "./dropdown";
import Text_area from "./text_area";
import Slider from "./slider";
import ReactiveButton from 'reactive-button';
import {BeatLoader} from "react-spinners";
import {safe_get} from "../utils";
import {useParams} from "react-router-dom";
import _ from 'lodash'
import {MultiSelectDropDown} from "./multi_select_dropdown";
import DropdownWithOthers from "./dropdown_with_others";

const Compose = observer((props: any) => {

  const [tokenCount, setTokenCount] = useState(0)
  const [composeResponse, setComposeResponse] = useState([])
  const [showCancelComposeBtn, setShowCancelComposeBtn] = useState(false)
  const [loading, setLoading] = useState(false)
  const [composeBtnState, setComposeBtnState] = useState('idle');
  const [expandCompose, setExpandCompose] = useState(true)
  const {toolsStore, documentStore} = useStore()
  const clear_compose = useRef();
  const params: any = useParams()
  const path = window.location.pathname


  const cancel_ref = useRef(false)

  useEffect(() => {
    if (!path.includes('/doc/') && params.id) {
      toolsStore.clear_compose_object()
      toolsStore.clear_active_tool_details()
      const id = parseInt(params.id)
      toolsStore.set_selected_tool_id(id)
      getToolDetails()
    }
  }, [])

  useEffect(() => {
    if (toolsStore.selectedToolId > 0 && path.includes('/doc/')) {
      toolsStore.clear_compose_object()
      toolsStore.clear_active_tool_details()
      getToolDetails()
    }
  }, [toolsStore.selectedToolId])

  const getToolDetails = async () => {
    const res = await toolsStore.getToolDetails(toolsStore.selectedToolId)
    if (res.status === 200) {
      toolsStore.setActiveToolDetails(res.data)
    } else {
      toast(res.data)
    }
  }

  const requestCompose = async () => {
    cancel_ref.current = false;
    setTokenCount(0)
    const res = await toolsStore.tokenCount(toolsStore.composeObject, toolsStore.activeToolDetails['id'])
    if (res.status === 200) {
      setTokenCount(res.data['total'])
      setShowCancelComposeBtn(true)
      setComposeBtnState('loading');
      // @ts-ignore
      clear_compose.current = setTimeout(() => {
        if (!cancel_ref.current) {
          setShowCancelComposeBtn(false)
          setComposeBtnState('success')
          confirmCompose();
          cancel_ref.current = true
        }
      }, 6000);
    }
  }

  const confirmCompose = async () => {
    setLoading(true)
    const res = await toolsStore.compose(toolsStore.composeObject, safe_get(toolsStore.activeToolDetails, 'id', ''))
    if (res.status === 200) {
      setComposeResponse(res.data)
      toolsStore.set_compose_response(res.data)
    } else {
      toast.error(res.data)
    }
    setLoading(false)
  }

  const cancelComposeHandler = () => {
    cancel_ref.current = true;
    setShowCancelComposeBtn(false)
    setComposeBtnState('idle')
    setTokenCount(0)
    if (cancel_ref.current) {
      clearTimeout(clear_compose.current);
    }
  }

  const renderInputs = (inputType, key) => {
    const sliderprop = safe_get(toolsStore.activeToolDetails, `slots.options.${key}`, {})
    const meta = safe_get(toolsStore.activeToolDetails, 'meta', {})
    const inputsKey = safe_get(toolsStore.activeToolDetails, `slots.inputs.${key}`, {})
    const optionsKey = safe_get(toolsStore.activeToolDetails, `slots.options.${key}`, {})
    switch (inputType) {
      case 'select-one' :
        return (
          <div key={key}><Dropdown
            category={safe_get(meta, 'category', '')}
            title={safe_get(meta, 'title', '')}
            display={safe_get(inputsKey, 'title', '')}
            values={safe_get(optionsKey, 'choices', {})}
            default={safe_get(optionsKey, 'default', '')}
            slot={key}/></div>
        )
      case 'select-one-others' :
        return (
          <div key={key}><DropdownWithOthers
            category={safe_get(meta, 'category', '')}
            title={safe_get(meta, 'title', '')}
            display={safe_get(inputsKey, 'title', '')}
            values={safe_get(optionsKey, 'choices', {})}
            default={safe_get(optionsKey, 'default', '')}
            slot={key}/></div>
        )
      case 'multi-select' :
        const choicesArr: any = []
        Object.keys(safe_get(optionsKey, 'choices', {})).map((key) => {
          choicesArr.push({'label': key, 'value': safe_get(optionsKey, `choices.${key}`, {})})
        })
        return (
          <div key={key}><MultiSelectDropDown
            category={safe_get(meta, 'category', '')}
            title={safe_get(meta, 'title', '')}
            display={safe_get(inputsKey, 'title', '')}
            values={choicesArr}
            default={safe_get(optionsKey, 'default', '')}
            slot={key}/></div>
        )
      case 'textarea' :
        return (
          <div key={key}>
            <Text_area
              rows={4}
              cols={50}
              placeholder={safe_get(inputsKey, 'placeholder', '')}
              category={safe_get(meta, 'category', '')}
              title={safe_get(meta, 'title', '')}
              display={safe_get(inputsKey, 'title', '')}
              suggestions={safe_get(inputsKey, 'suggestions', [])}
              slot={key}
            />
          </div>
        )
      case 'slider' :
        return (
          <div key={key}><Slider key={key} slot={key} display={safe_get(inputsKey, 'title', '')}
                                 defaultVal={safe_get(sliderprop, 'default', 'name')}
                                 max={safe_get(sliderprop, 'max', 1)}
                                 min={safe_get(sliderprop, 'min', 0.1)}
                                 step={safe_get(sliderprop, 'step', 0.1)}/></div>
        )
      default:
        return (
          <div key={key}>default</div>
        )
    }
  }

  const onClickHandler = () => {
    requestCompose()
  };

  const expandHandler = () => {
    setExpandCompose(!expandCompose)
  }

  const direct_input_keys = Object.keys(safe_get(toolsStore.activeToolDetails, 'slots.inputs', {}))
  const ordered_input_keys = safe_get(toolsStore.activeToolDetails, 'slots.ordered_inputs', direct_input_keys)

  return (
    <>
      {toolsStore.selectedToolId > 0 && !_.isEmpty(toolsStore.activeToolDetails) &&
        <div className='border-2 mt-2 rounded shadow bg-gray-100 border-gray-100'>
          <div className='flex' onClick={expandHandler}>
            <div className='text-lg font-bold uppercase text-gray-600 p-4'>Compose</div>
            <div className='ml-auto w-fit p-4'>
              {expandCompose ?
                <i className="fa fa-solid fa-caret-up mr-4 fs-26 cursor-pointer"/> :
                <i className="fa fa-solid fa-caret-down mr-4 fs-26 cursor-pointer"/>
              }
            </div>
          </div>
          {expandCompose &&
            <>
              <div className='text-2xl font-bold pt-2'>{safe_get(toolsStore.activeToolDetails, 'meta.title', '')}</div>
              <div className='text-lg text-gray-500'>{safe_get(toolsStore.activeToolDetails, 'meta.subtitle', '')}</div>
              <div className='flex flex-row flex-wrap'>
                {
                  safe_get(toolsStore.activeToolDetails, 'meta.tags', []).length ?
                    toolsStore.activeToolDetails['meta']['tags'].map((tag) => {
                      return (<div key={tag} className='border py-1 mx-1 px-4 bg-indigo-200 rounded-xl'>{tag}</div>)
                    }) : ''
                }
              </div>

              <div>
                {ordered_input_keys.map((key) => (
                  renderInputs(safe_get(toolsStore.activeToolDetails, `slots.inputs.${key}.type`, ''), key)
                ))}
              </div>

              <div className='w-100 '>
                <ReactiveButton
                  className='bg-indigo-200 rounded self-end ml-auto'
                  buttonState={composeBtnState}
                  idleText={loading ? "Compose Request Sent" : "Compose"}
                  loadingText="Sending Compose Request..."
                  successText="Compose Request Sent"
                  onClick={onClickHandler}
                  disabled={loading}
                />
              </div>

              {tokenCount ?
                <div className='flex'>
                  <div className='p-2 text-xl'>Cost: ~ <span className='font-bold'>{tokenCount}</span> tokens to compose</div>
                  {showCancelComposeBtn && <button onClick={cancelComposeHandler} className='border-2 p-1 m-2 bg-red-100 rounded-md'>Cancel</button>}
                </div> : ''}

              {(loading || composeResponse.length > 0) && <div className='border-2 m-2 rounded shadow p-2 w-3/4'>
                {(loading) &&
                  <>
                    <p className='text-xl pb-2'>Generating Text...</p>
                    <BeatLoader
                      color={"blue"}
                      loading={true}
                      size={20}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </>}

                {(composeResponse.length > 0) ?
                  <>
                    <div className='text-xl pb-2'>Generated Text:</div>
                    {composeResponse.map((txt, idx) => (
                      <div key={idx} className='py-2'>
                        <div className='flex pb-2'>
                          <div className='font-bold'>Option {idx + 1}:</div>
                          {path.includes('/doc/') &&
                            <div className='text-blue-500 cursor-pointer ml-auto w-fit hover:text-blue-900' onClick={() => documentStore.append_paragraph_to_active_document(txt)}>Copy to Editor</div>
                          }
                        </div>
                        <div key={`gentool_${idx}`} className='p-1'>{txt}</div>
                      </div>
                    ))}
                  </>
                  : ''}
              </div>
              }
            </>
          }
        </div>
      }
    </>
  )
})
export default Compose
