import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";
import {useStore} from "../../hooks/use-store";

const DropdownWithOthers = observer((props: any) => {
  const {toolsStore} = useStore()
  const [showOthersTextBox, setShowOthersTextBox] = useState(false)
  const [othersText, setOthersText] = useState('')

  const valueChangeHandler = (evt) => {
    if (evt.target.value === 'others') {
      setShowOthersTextBox(true)
    } else {
      setShowOthersTextBox(false)
      toolsStore.updateComposeObject(props.slot, evt.target.value)
    }
  }

  const textValueChangeHandler = (event) => {
    setOthersText(event.target.value)
    toolsStore.updateComposeObject(props.slot, event.target.value)
  }

  useEffect(() => {
    toolsStore.updateComposeObject(props.slot, props.default)
  }, [])

  return (
    <div className='flex flex-col py-4'>
      <label className='py-2 font-bold'>{props.display}</label>
      <select onChange={valueChangeHandler} className='border-2 rounded-md p-2' name={props.display} id={props.display}>
        {Object.keys(props.values).map((keyName) => (
          keyName === props.default ?
            <option key={keyName} value={props.values[keyName]} selected>{keyName}</option> :
            <option key={keyName} value={props.values[keyName]}>{keyName}</option>
        ))}
        <option key='others' value='others'>Others</option>
      </select>

      {showOthersTextBox &&
        <div className='flex flex-col mt-2'>
          <label>Please enter 'other' option here</label>
          <input className='border rounded shadow p-2' type='text' value={othersText} onChange={textValueChangeHandler}/>
        </div>
      }

    </div>
  )
})
export default DropdownWithOthers
