import React from 'react';
import { useStore } from '../../../hooks/use-store';
import {observer} from "mobx-react-lite";
const UsersName = observer(() => {
  const handleUserNameChange = (event) => {
    if(resumeStore.current_step_num==0){
    userProfileStore.updateUserProfileJsonData('user_name', event.target.value);
  }  };
  const {userProfileStore,resumeStore} = useStore();
  return (
    <div>
      <div className="w-89 font-avenir font-light text-[16px] leading-[21.86px] text-[#737E93]">
        <span >User's Name</span>
        <span className='text-red-500 text-[18px] ml-2'>*</span>
      </div>
      <div className="flex flex-col">
        <input
          type="text"
          placeholder="Enter name"
          className="w-full border border-[#737E93] rounded px-2 py-1 text-[16px]"
          value={userProfileStore.userProfilejsonData.user_name}
          onChange={handleUserNameChange}
        />
      </div>
    </div>
  );
});



export default UsersName;
