import React, { useEffect, useState } from 'react';
import { useStore } from '../../hooks/use-store';
import { safe_get } from '../utils';
import { useNavigate } from 'react-router';
import folderOpenIcon from '../../icons/folderOpen.svg';
import folderCloseIcon from '../../icons/folderClose.svg';
import fileIcon from '../../icons/file.svg';

const Tree = ({ data }) => {
  return (
    <div className="d-tree">
      <ul className="flex py-[0px] flex-col">
        {data &&
          data.map((tree: any) => (
            <div key={tree.id} id={tree.id}>
              <TreeNode
                children={tree.children}
                value={{ activeFolder: tree.name, parentId: tree.parent_id }}
                node={tree}
                id={tree.id}
              />
            </div>
          ))}
      </ul>
    </div>
  );
};

const TreeNode = ({ node, value, children, id }) => {
  const [childVisible, setChildVisiblity] = useState(false);
  const { newContentStore,sopStore, sopProfileSelectionStore } = useStore();

  let hasChild = node.children ? true : false;
  const navigate = useNavigate();
  function handleClick(id: any): void {
    sopProfileSelectionStore.setSelectionParentId(true);
    newContentStore.activeFolder = id;
    sopProfileSelectionStore.sopProfileFolderId =  id;
    
  }
  
  useEffect(() => {
    if (newContentStore.activeFolder == id) {
      newContentStore.set_children(children)
      setChildVisiblity(true);
      hasChild = true;
    }
  }, [newContentStore, node]);
  useEffect(()=>{
    if(!sopProfileSelectionStore.sopSelectionParentId){
    sopProfileSelectionStore.sopProfileFolderId=newContentStore.activeFolder 
    }
    
  },[])

  const item_click = (item) => {

    if (!sopProfileSelectionStore.showSopSelectionCreationModal) {
      const isfile = safe_get(item, 'isfile', false);
      if (!isfile) {
        // nothing
      } else {
        if (item['tool_id'] === 152) {
          navigate(`/sop/${item['id']}/${item['parent_id']}`);
        } else if (item['tool_id'] === 153) {
          navigate(`/uksop/${item['id']}/${item['parent_id']}`);
        } else if (item['tool_id'] === 154) {
          navigate(`/canadasop/${item['id']}/${item['parent_id']}`);
        } else {
          return 'Not Found';
        }
        sopStore.setFinalOutputScreen(true);
        //handle file click
      }
    }
  };

  const MAX_LENGTH = 10;

  return (
    <li className={`px-[0rem] border-0`}>
      <div
        className={`flex items-center w-full pt-[1rem] pb-[0.6rem] px-[1rem] ${
          newContentStore.activeFolder == id && 'bg-[#FCF3FF] py-[1rem]'
        }`}
      >
        {hasChild && !node.isfile && (
          <div
            onClick={(e) => {
              setChildVisiblity((v) => !v);
              newContentStore.activeFolder = null;
            }}
            className={`d-inline d-tree-toggler mr-2`}
          >
            <i
              className={`far ${
                childVisible ? 'fa-light fa-angle-down' : 'fa-light fa-angle-right'
              }  text-[#737E93] fs-16 cursor-pointer`}
            />
          </div>
        )}
        <div
          onClick={(e) => {
           handleClick(id);
            if (!node.isfile) {
              setChildVisiblity(true);
              newContentStore.children = children;
            }
          }}
          className="flex d-tree-head cursor-pointer"
        >
          {childVisible ? (
            <img className="cursor-pointer mr-3 w-6 h-4" src={folderOpenIcon} />
          ) : hasChild && node.isfile ? (
            <img className="cursor-pointer ml-4 mr-3" src={'/sop_file.svg'} />
          ) : (
            <img className="cursor-pointer mr-3" src={folderCloseIcon} />
          )}
          <div
            onClick={() => item_click(node)}
            className={` ${childVisible ? 'text-[18px] text-[#3C424C]' : 'text-[16px] #4E5664'}`}
          >
            {' '}
            {node.name.length > MAX_LENGTH ? (
              <div title={node.name}>
                {`${node.name.substring(0, MAX_LENGTH)}...`}
              </div>
            ) : (
              <p>{node.name}</p>
            )}
            {/* {node.name} */}
          </div>
        </div>
      </div>

      {hasChild && childVisible && (
        <div className="d-tree-content">
          <ul className="flex py-[0px] px-[20px] flex-col">
            <Tree data={node.children} />
          </ul>
        </div>
      )}
    </li>
  );
};

export default Tree;
