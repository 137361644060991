import { $createLinkNode } from '@lexical/link';
import { $createListItemNode, $createListNode } from '@lexical/list';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { $createHeadingNode, $createQuoteNode } from '@lexical/rich-text';
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import * as React from 'react';
import EditorNodes from './nodes/EditorNodes';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';
import { SharedHistoryContext } from './context/SharedHistoryContext';
import { TableContext } from './plugins/TablePlugin';
import { SharedAutocompleteContext } from './context/SharedAutocompleteContext';
import Editor from './Editor';
import Settings from './Settings';
import DocsPlugin from './plugins/DocsPlugin';
import PasteLogPlugin from './plugins/PasteLogPlugin';
import TestRecorderPlugin from './plugins/TestRecorderPlugin';
import TypingPerfPlugin from './plugins/TypingPerfPlugin';
import './editor-shell.css';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import { useSettings } from './context/SettingsContext';
import useAIAssistInvoker from './hooks/useAIAssistInvoker';
import AIAssistHomeScreen from './aiassist/ai-assist-home-screen';


export function CambriRichEditor() {

  const initialConfig = {
    editorState: null,
    namespace: 'Cambri',
    nodes: [...EditorNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme
  };

  const {
    settings: {
      isCollab,
      isAutocomplete,
      isMaxLength,
      isCharLimit,
      isCharLimitUtf8,
      isRichText,
      showTreeView,
      showTableOfContents,
      shouldUseLexicalContextMenu,
      tableCellMerge,
      tableCellBackgroundColor
    }
  } = useSettings();

  return (
    <>
      <div style={{ backgroundColor: '#eee' }}>
        <div className='editor-shell-container'>
          <div className='editor-header flex items-center'>
            <div className='hamburger cursor-pointer rounded-full flex flex-row items-center justify-center'>
              <i className="fa-regular fa-bars"/>
            </div>

            <div className='ml-6 flex flex-col'>
              <div className='doc-title'>New Document</div>
              <div className='flex flex-row items-center mt-2'>
                <i className="folder-icon fa-light fa-folder-open"/>
                <div className='doc-sub-title mx-2'>Birmingham University</div>
                <i className="edit-icon cursor-pointer fa-solid fa-pen" title='Move to folder'></i>
              </div>

            </div>

          </div>
          <LexicalComposer initialConfig={initialConfig}>

            <SharedHistoryContext>
              <TableContext>
                <SharedAutocompleteContext>
                  {/*<header>
                <a href="/" target="_blank" rel="noreferrer">
                  <img src="/cambri-logo-color.png" alt="Cambri"/>
                </a>
              </header>*/}
                  {isRichText && <ToolbarPlugin/>}
                  <div className="editor-shell">
                    <Editor/>
                  </div>
                  {/*<Settings/>*/}
                  {/*<DocsPlugin/>*/}
                  {/*<PasteLogPlugin/>*/}
                  {/*<TestRecorderPlugin/>*/}
                  {/*<TypingPerfPlugin/>*/}
                </SharedAutocompleteContext>
              </TableContext>
            </SharedHistoryContext>
          </LexicalComposer>
        </div>
      </div>
    </>
  );
}
