import apiSauce from "./api-sauce";

export const apiInterceptor = (userStore: any) => {

  apiSauce.apisauce.axiosInstance.interceptors.request.use(
    (config) => {
      config.headers["X-Token"] = userStore.token
      console.log("req - config", config)
      return config
    },
    (error) => {
      Promise.reject(error)
    },
  )

  apiSauce.apisauce.addMonitor(monitor => {
    if (monitor.status === 436) {
      const token_expired_event = new Event("token-expired");
      window.dispatchEvent(token_expired_event);
    }
  })
}
