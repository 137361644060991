import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  onClick: () => void;
  onBoard:boolean;
}

const AddButton = ({ children, onClick,onBoard }: Props) => {
  return (
    <button
      type="button"
      className={` ${onBoard ? 'text-center w-[200px] h-[40px] border border-solid my-[20px]' : 'my-[60px] w-[600px] h-[40px] border border-solid md:border-dashed'} text-sm flex justify-center items-center bg-[#FCF3FF] uppercase font-bold text-[#A033FF] border-[#A033FF]`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default AddButton;
