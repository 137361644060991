import {observer} from "mobx-react-lite";
import {useStore} from "../../../hooks/use-store";
import {safe_get} from "../../utils";
import React, {useState} from "react";
import _ from 'lodash'

const Current_History_Details = observer((props: any) => {
  const {toolsStore, documentStore} = useStore()
  const [expandHistory, setExpandHistory] = useState(false)

  const expandHandler = () => {
    setExpandHistory(!expandHistory)
  }

  return (
    <>
      {!_.isEmpty(toolsStore.current_history_details) &&
        <div className='border rounded-lg shadow-lg flex flex-col bg-gray-100 border-gray-100'>
          <div className='flex' onClick={expandHandler}>
            <div className='text-lg font-bold uppercase text-gray-600 p-4'>History Details</div>
            <div className='ml-auto w-fit p-4'>
              {expandHistory ?
                <i className="fa fa-solid fa-caret-up mr-4 fs-26 cursor-pointer"/> :
                <i className="fa fa-solid fa-caret-down mr-4 fs-26 cursor-pointer"/>
              }
            </div>
          </div>
          {/*<div className='font-bold uppercase text-xl self-center pb-2'>History Details</div>*/}
          {expandHistory && <div className=''>
            {toolsStore.current_history_details.length > 0 ?
              toolsStore.current_history_details.map((hist, idx) => {
                return (
                  <>
                    <div className='p-2 font-bold'>History {idx + 1}</div>
                    <div className='border shadow p-2 m-1'>
                      {
                        safe_get(hist, 'output.choices', []).length > 0 ?
                          safe_get(hist, 'output.choices', []).map((choice, index) => {
                            return <div className='mb-2'>
                              <div className='flex pb-2'>
                                <div className='text-sm font-bold'>Choice {index + 1}</div>
                                <div className='text-blue-500 cursor-pointer ml-auto w-fit hover:text-blue-900' onClick={() => documentStore.append_paragraph_to_active_document(choice)}>Copy to Editor</div>
                              </div>
                              {choice}
                            </div>
                          })
                          : ''
                      }
                      <div className='font-bold'>{safe_get(hist, 'ts', '')}</div>
                    </div>
                  </>
                )
              })
              : ''}
          </div>
          }
        </div>
      }
    </>
  )
})

export default Current_History_Details
