import {observer} from "mobx-react-lite";
import {useStore} from "../../hooks/use-store";
import React, {useEffect, useState} from "react";

const Slider = observer((props: any) => {
  const {toolsStore} = useStore()
  const [valArr, setValArr] = useState([])

  function prepareValues() {
    const valuesArr: any = [];
    for (let i = parseFloat(props.min); i <= parseFloat(props.max); i = i + parseFloat(props.step)) {
      valuesArr.push(i)
    }
    setValArr(valuesArr)
  }

  useEffect(() => {
    setValArr([])
    prepareValues()
    toolsStore.updateComposeObject(props.slot, parseFloat(props.defaultVal))
  }, [])

  const sliderValChangeHandler = (evt) => {
    try {
      const updated_val = parseFloat(evt.target.value)
      toolsStore.updateComposeObject(props.slot, updated_val)
      console.log('onmousedown', updated_val)
    } catch (e) {

    }
  }
  return (
    <div className='flex flex-col py-2'>
      <label className='py-2 font-bold'>{props.display}</label>
      <div className='py-2 w-full'>
        <input
          type="range"
          min={parseFloat(props.min)}
          max={parseFloat(props.max)}
          defaultValue={parseFloat(props.defaultVal)}
          className="range"
          step={parseFloat(props.step)}
          onMouseLeave={sliderValChangeHandler}
        />
        <div className="w-full flex justify-between text-xs px-2">
          {valArr.length && valArr.map((val) => <span key={val}>{Math.round(val * 100) / 100}</span>)}
        </div>
      </div>
    </div>
  )
})
export default Slider
