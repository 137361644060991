import {action, makeAutoObservable, runInAction, toJS} from "mobx";
import {makePersistable} from 'mobx-persist-store';
import {safe_get} from "../pages/utils";
import _, {clone, cloneDeep} from "lodash";
import {toast} from "react-toastify";
import {ResumeAPI} from "../api/resume-api";
import { UserProfileApi } from "../api/user-profile-api";



const sop_selection_progress_stepper_states = [
    {
      num:0,
      key:'users',
      name:'Select user for writing their SOP',
    },
    {
      num: 1,
      key: 'folders',
      name: 'Select folder to save the SOP file',
    },
    ];
  



export class SopProfileSelectionStore {
  current_sop_selection_step_num: number = sop_selection_progress_stepper_states[0]['num'];
  current_sop_selection_step: any = sop_selection_progress_stepper_states[0];

  showSopSelectionCreationModal: boolean = false;
  sopSelectionContinueClicked: boolean = false;
  sopSelectionContinueButton: boolean = true;
  sopSelectionBackButton: boolean = true;
  sopSelectionNavigate: boolean = false;

  sopProfileSelection: { label: string; value: string } | null = null;

  sopProfileSelectionDropdownValues: { label: string; value: string }[] = [];
  sopProfileSelectionValues: { user_name: string; profile_id: string }[] = [];
  current_sop_name: string ='';

  sopSelectionEdit: boolean = false;
  sopProfileFolderId: any = null;
  sopSelectionParentId: boolean =false;
  @action
  setSelectionParentId(value:any){
    this.sopSelectionParentId=value;
  }

  @action
  setProfileSelection(profile: any) {
    this.sopProfileSelection = profile;
  }

  @action
  set_current_sop_name(sop: any) {
    this.current_sop_name = sop;
  }

  @action
  setSopSelectionEdit(value: any) {
    this.sopSelectionEdit = value;
  }

  constructor(private userProfileApi: UserProfileApi) {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'SopProfileSelectionStore',
      properties: [],
      storage: window.localStorage,
    });
  }

  @action
  resetSopSelection(){
    this.sopProfileSelection =null;
    this.sopSelectionEdit =false;
     this.current_sop_name ='';
  }

  @action set_current_step(value, name) {
    this.current_sop_selection_step_num = value;
    this.current_sop_selection_step['num'] = value;
    this.current_sop_selection_step['name'] = name;
  }

  @action
  setSopSelectioNavigate(value) {
    this.sopSelectionNavigate = value;
  }

  @action
  async getSopProfileSelectionDropdownValues() {
    const response = await this.userProfileApi.get_user_profile_name_id_list();
    if (response.status == 200) {
      this.sopProfileSelectionValues = response.data.user_profiles;
      this.setSopProfileSelectionDropdownValues()
    } else {
      this.sopProfileSelectionValues = [];
      this.sopProfileSelectionDropdownValues =[];
    }
  }

  @action
  setSopProfileSelectionDropdownValues() {
    this.sopProfileSelectionDropdownValues = this.sopProfileSelectionValues.map((item) => {
      return {
        label: item.user_name !== null ? item.user_name : '',
        value: item.profile_id,
      };
    });
  }

  @action
  setSopSelectionContinueClicked(value) {
    this.sopSelectionContinueClicked = value;
  }
  @action
  setSopSelectionContinueButton(value) {
    this.sopSelectionContinueButton = value;
  }
  @action
  setSopSelectionBackButton(value) {
    this.sopSelectionBackButton = value;
  }

  @action
  show_sop_selection_creation_modal() {
    this.showSopSelectionCreationModal = true;
  }

  @action
  hide_sop_selection_creation_modal() {
    this.showSopSelectionCreationModal = false;
  }

  @action
  sop_selection_progress_nav_next() {
    const new_idx = this.current_sop_selection_step_num + 1;
    if (new_idx >= sop_selection_progress_stepper_states.length) return;
    this.current_sop_selection_step_num = new_idx;
    this.current_sop_selection_step = sop_selection_progress_stepper_states[new_idx];
    console.log('Step:', this.current_sop_selection_step_num);
  }

  @action
  sop_selection_progress_nav_previous() {
    const new_idx = this.current_sop_selection_step_num - 1;
    if (new_idx < 0) return;
    this.current_sop_selection_step_num = new_idx;
    this.current_sop_selection_step = sop_selection_progress_stepper_states[new_idx];
  }

  @action
  sop_selection_progress_is_last_step() {
    return this.current_sop_selection_step_num === sop_selection_progress_stepper_states.length - 1;
  }

  @action
  sop_selection_progress_get_current_state() {
    return `${this.current_sop_selection_step_num + 1}/${
      sop_selection_progress_stepper_states.length
    }`;
  }
  @action
  sop_selection_progress_get_previous_state() {
    return `${this.current_sop_selection_step_num - 1}/${
      sop_selection_progress_stepper_states.length
    }`;
  }
  @action
  sop_selection_progress_stepper_status() {
    const pre = this.current_sop_selection_step_num;
    const post =
      sop_selection_progress_stepper_states.length - this.current_sop_selection_step_num - 1;
    return { pre, post };
  }

  @action
  resetState() {
    this.showSopSelectionCreationModal = false;
    this.current_sop_selection_step_num = sop_selection_progress_stepper_states[0]['num'];
    this.current_sop_selection_step = sop_selection_progress_stepper_states[0];;
    this.sopSelectionContinueClicked = false;
    this.sopSelectionNavigate = false;
    this.sopSelectionContinueButton = true;
    this.sopSelectionBackButton = true;
  }
}

