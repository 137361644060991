import { useState } from 'react';
import { PlusButton } from '../../../components/plus-button/plus-button';
import { MinusButton } from '../../../components/plus-button/minus-button/minus-button';
import { v4 as uuidv4 } from 'uuid';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../hooks/use-store';
import { safe_get } from '../../utils';
const FetchSkills = observer((props: any) => {
  const { userProfileStore } = useStore();
  const [stateData, setStateData] = useState<any[]>([]);
  const defaultState = [
    {
      id: uuidv4(),
      name: 'Level of Education',
    },
    {
      id: uuidv4(),
      name: 'Field of Study',
    },
    {
      id: uuidv4(),
      name: 'Leadership',
    },
    {
      id: uuidv4(),
      name: 'Managing',
    },
    {
      id: uuidv4(),
      name: 'SQL',
    },
    {
      id: uuidv4(),
      name: 'Java Script',
    },
  ];
  const handleClick = (name: string) => {
    // It updates the state by marking the skill as selected in stateData.
    const updatedState = stateData.map(item => {
      if (item.name === name) {
        return {
          ...item,
          selected: true,
        };
      }
      return item;
    });
    setStateData(updatedState);
    userProfileStore.updateUserProfileJsonData('skills', [
      ...safe_get(userProfileStore.userProfilejsonData, 'skills', []),
      name,
    ]);
  };
  const handleUndo = (index: number) => {
    // It updates the state by marking the skill as unselected in stateData
    const selectedSkill = stateData[index].name;
    const updatedState = stateData.map(item => {
      if (item.name === selectedSkill) {
        return {
          ...item,
          selected: false,
        };
      }
      return item;
    });
    setStateData(updatedState);
    const skills = safe_get(userProfileStore.userProfilejsonData, 'skills', []);
    const updatedSkills = skills.filter((skill: string) => skill !== selectedSkill);
    userProfileStore.updateUserProfileJsonData('skills', updatedSkills);
  };
  if (stateData.length === 0) {
    setStateData(defaultState);
  }
  return (
    <>
      {stateData &&
        stateData.length > 0 &&
        stateData.map((item, index) => {
          return (
            <div className="flex items-center" key={item.id}>
              <button
                className={`flex border border-[#C7CBD4] rounded-md px-3 py-2 w-full my-2 text-[#4E5664] text-sm ${
                  item.selected ? 'bg-gray-200' : ''
                }`}
              >
                {item.name}
              </button>
              <div className="flex items-center justify-center">
              {!item.selected && (
              <PlusButton name={item.name} handleClick={handleClick} disabled={!item.selected && stateData.some(skill => skill.selected)} />
              )}
               {item.selected && (
                <MinusButton index={index} handleUndo={handleUndo} disabled={!item.selected} />
                )}
              </div>
            </div>
          );
        })}
    </>
  );
});
export default FetchSkills;