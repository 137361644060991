import {action, makeAutoObservable, toJS} from "mobx";
import {makePersistable} from "mobx-persist-store";
import {DocumentApi} from "../api/document-api";
import {safe_get} from "../pages/utils";
import {makeid_alpa_numeric} from "../utils";

export class DocumentStore {
  isError: boolean = false;
  errorMessage: string = '';
  loading: boolean = false
  active_document = {}
  active_document_id = 0
  editor_id: string = makeid_alpa_numeric(6)

  constructor(private documentApi: DocumentApi) {
    makeAutoObservable(this);
    makePersistable(this, {name: 'DocumentStore', properties: [], storage: window.localStorage});
  }

  @action
  update_active_document_json(data) {
    this.active_document['doc'] = data
  }

  @action
  refresh_editor_id() {
    this.editor_id = makeid_alpa_numeric(6)
  }

  @action
  get_active_document_json() {
    const default_val = {"blocks": [], "entityMap": {}}
    try {
      const doc = safe_get(toJS(this.active_document), 'doc', default_val)
      if (Object.keys(doc).length === 0) {
        return default_val
      }
      return doc
    } catch (e) {
      return default_val
    }
  }

  @action
  append_paragraph_to_active_document(text) {
    const block = {
      "data": {},
      "depth": 0,
      "entityRanges": [],
      "inlineStyleRanges": [],
      "key": makeid_alpa_numeric(5),
      "text": text,
      "type": "paragraph",
    }
    const doc = this.get_active_document_json()
    doc['blocks'].push(block)
    this.update_active_document_json(doc)
    this.refresh_editor_id()
  }

  @action
  async get_document_details(id: number) {
    this.loading = true;
    const payload = {
      'id': id
    }
    const resp = await this.documentApi.get_document_details(payload);
    if (resp.status === 200) {
      this.set_active_document_id(id)
      this.set_active_document(resp.data)
      console.log('doc details', resp.data)
      this.loading = false
    } else {
      this.isError = true
      this.loading = false
      this.errorMessage = resp.data
    }
    return resp
  }

  @action
  async save_active_document() {
    this.loading = true
    const res = await this.documentApi.update_document_details(this.active_document)
    this.loading = false
    return res
  }

  @action
  set_active_document(doc) {
    this.active_document = doc
  }

  @action
  set_active_document_id(id) {
    this.active_document_id = id
  }

  @action
  async delete_file(id) {
    this.loading = true
    const payload = {
      'id': id
    }
    const resp = await this.documentApi.delete_file(payload)
    this.loading = false
    return resp
  }

  @action
  async delete_folder(id) {
    this.loading = true
    const payload = {
      'id': id
    }
    const resp = await this.documentApi.delete_folder(payload)
    this.loading = false
    return resp
  }

  @action
  async rename_folder(id, name) {
    this.loading = true
    const payload = {
      'id': id,
      'name': name
    }
    const resp = await this.documentApi.rename_folder(payload)
    this.loading = false
    return resp
  }

  @action
  async rename_file(id, name) {
    this.loading = true
    const payload = {
      'id': id,
      'name': name
    }
    const resp = await this.documentApi.rename_file(payload)
    this.loading = false
    return resp
  }

}
